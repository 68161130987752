import PropTypes from 'prop-types';
import React from 'react';

import { buildAirportName } from '../../../../../helpers/utils';

const SuggestionsList = ({
  options,
  onClick,
  activeOptionIndex,
  setActiveOptionIndex
}) => (
  <div>
    <ul className="suggestion-list">
      {options.length ? (
        options.map(({ id = 0, airportCity, code, airportCountry }, i) => {
          return (
            <li
              key={id}
              className={`item ${
                id && activeOptionIndex === i ? 'active' : ''
              }`}
              onClick={() => {
                onClick(id);
              }}
              onMouseEnter={() => id && setActiveOptionIndex(i)}
            >
              <p>
                {buildAirportName({ id, airportCity, code, airportCountry })}
              </p>
            </li>
          );
        })
      ) : (
        <li className="item">
          <p>
            {buildAirportName({
              id: 0,
              airportCity: { name: 'No such results' },
              code: null,
              airportCountry: null
            })}
          </p>
        </li>
      )}
    </ul>
  </div>
);

export default SuggestionsList;

SuggestionsList.propTypes = {
  activeOptionIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array,
  setActiveOptionIndex: PropTypes.func.isRequired
};

SuggestionsList.defaultProps = {
  options: []
};
