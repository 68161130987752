import React, { useCallback } from 'react';
import CloseIcon from '../../Icons/CloseIcon';
import LinkUrl from '../../LinkUrl';
import { useWindowSize } from '../../../helpers/useWindowSize';
import FormExit from './FormExit/FormExit';
import { hidePopup, showPopup } from '../../../helpers/defaultPopup';
import './index.css';

const NO_EXIT_INTENT_PAGES = [
  '/aboutus/',
  '/affiliates/',
  '/terms-conditions/',
  '/our-culture/',
  '/contact-us/',
  '/refer-and-earn-at-travelopod/',
  '/privacy-policy/',
  '/bookingrules/',
  '/updates/'
];

const ExitIntentPopup = ({
  onInitChangeSeach,
  onInitToggleModal,
  toggleIsShownExitModal,
  isShownExitModal,
  trustpilotBgShow
}) => {
  const wrapperRef = React.useRef(null);
  const [width] = useWindowSize();
  let url;
  if (typeof window !== 'undefined') {
    url = new URL(window.location);
  }
  const isNoExitIntentPage =
    typeof window !== 'undefined' &&
    NO_EXIT_INTENT_PAGES.indexOf(window.location.pathname) > -1;

  React.useEffect(() => {
    document
      .querySelector('body')
      .addEventListener('mouseleave', handleMouseleave, {
        passive: true,
        capture: false
      });
    document
      .querySelector('body')
      .addEventListener('click', handleClickOutside, {
        passive: true,
        capture: false
      });

    window.history.pushState({}, '', url);
  }, []);

  const handleMouseleave = event => {
    if (!isNoExitIntentPage && event.clientY < 0) {
      if (sessionStorage.getItem('modalExitIntentPopup') !== 'true') {
        toggleIsShownExitModal(!isShownExitModal);
        sessionStorage.getItem('modalExitIntentPopup') !== 'true' &&
          sessionStorage.setItem('modalExitIntentPopup', 'true');
        showPopup();
      }
    }
  };

  const handleClickOutside = useCallback(event => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !(event.target.id === 'switchAdditionalForm') &&
      !(event.target.id === 'disable-cookies-banner')
    ) {
      toggleIsShownExitModal(false);
    }
  });

  React.useEffect(() => {
    if (isShownExitModal) {
      return () => {
        hidePopup();
        document
          .querySelector('body')
          .removeEventListener('click', handleClickOutside, {
            passive: true,
            capture: false
          });
        document
          .querySelector('body')
          .removeEventListener('mouseleave', handleMouseleave, {
            passive: true,
            capture: false
          });
      };
    }
  }, [handleClickOutside, isShownExitModal]);

  const closeExitModal = () => {
    toggleIsShownExitModal(false);
    sessionStorage.setItem('modalExitIntentPopup', true);
    hidePopup();
  };

  onInitToggleModal(isShownExitModal);

  return (
    <React.Fragment>
      <div
        id="modal_form-exit"
        className={`modal-block ${
          !isShownExitModal ? 'hideModalElement' : 'showModalElement'
        }`}
        ref={wrapperRef}
      >
        <div className="modal-wrapper">
          <CloseIcon handleClick={() => closeExitModal()} />
          <div className="modal-text">
            <h2 className="h2 ">LET US SEND YOU BEST BARGAIN DEAL</h2>
            <h2 className="h2 text-color">
              & Enter you for a chance to win a free trip{' '}
              <span>($1000 Giveaway)</span>
            </h2>
            <div className="wrapper-modal">
              <form className="content-popup">
                <FormExit
                  onInitChangeSeach={onInitChangeSeach}
                  onInitToggleModal={onInitToggleModal}
                  toggleIsShownExitModal={toggleIsShownExitModal}
                  hidePopup={hidePopup}
                />
              </form>
              <div className="info">
                <LinkUrl
                  path="/win-a-trip/terms-conditions"
                  className="link-info"
                >
                  Terms & Conditions
                </LinkUrl>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`trust-widget ${
            width > 768 ? 'showModalElement' : 'hideModalElement'
          } `}
        >
          <div className="hide-link-tp-home"></div>
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="51c1ebb90000640005477b45"
            data-style-height="130px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
          >
            {trustpilotBgShow && <div className="trustpilot-bg" />}
          </div>
        </div>
      </div>
      <div
        id="overlay-exit-modal"
        className={`overlay-modal ${
          !isShownExitModal ? 'hideModalElement' : 'showModalElement'
        }`}
      ></div>
    </React.Fragment>
  );
};
export default ExitIntentPopup;
