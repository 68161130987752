import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ number, className, children, onClick, id }) => (
  <a href={number} className={className} onClick={onClick} id={id}>
    {children}
  </a>
);

Link.propTypes = {
  className: PropTypes.string,
  number: PropTypes.string
};

Link.defaultProps = {
  className: '',
  number: ''
};
export default Link;
