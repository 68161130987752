import React from 'react';
import './index.css';

const TrustpilotInfoBlock = ({ trustpilotBgShow }) => (
  <React.Fragment>
    <div className="block-info">
      <div className="wrapper-trustpilot-bbb">
        <div className="hide-link-mobile"></div>
        <div
          className="trustpilot-widget trustpilot-widget-horizontal"
          data-locale="en-US"
          data-template-id="5406e65db0d04a09e042d5fc"
          data-businessunit-id="51c1ebb90000640005477b45"
          data-style-height="100%"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
        >
          {trustpilotBgShow && <div className="trustpilot-bg" />}
        </div>
        <img
          className="lazy is-desktop"
          src="/../../images/BBB_seal.png"
          alt="A+BBB"
        />
        <img
          className="lazy is-mobile"
          src="/../../images/a-rate.png"
          alt="A+BBB"
        />
      </div>
    </div>
  </React.Fragment>
);

export default TrustpilotInfoBlock;
