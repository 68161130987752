/* eslint camelcase: 0 */
import { createUrlWithParams, getUrlParams } from './urlParams';
import { callTrackingApiUrl } from '../configs';

export const DEFAULT_PHONE = '19292698013';

export const setupCallTracking = () => {
  const newCentury = createUrlWithParams({ path: '' }).replace('?', '');

  const previouslyCentury = localStorage.getItem(
    'call_tracking_initial_params'
  );
  if (newCentury !== previouslyCentury) {
    localStorage.removeItem('call_tracking_initialNumber');
    localStorage.setItem('call_tracking_initial_params', newCentury);
  }
};

export const collectData = IATA => {
  const { tpodtfn, utm_source } = getUrlParams();
  const initNumber = localStorage.getItem('call_tracking_initialNumber');

  return {
    ...(tpodtfn && { tpodtfn }),
    ...(utm_source && { utmSource: utm_source }),
    ...(initNumber && IATA && { destinationIata: IATA })
  };
};

export const updatePhoneNumber = async (IATA, domain) => {
  try {
    if (IATA === 'XYZ') {
      return '18052654930';
    }

    const url = `${callTrackingApiUrl}/call-tracking/phone`;
    const requestBody = collectData(IATA);
    const response = await fetch(
      // TODO - modify when building call tracking API for canada page
      // set tpodmkt as canada
      createUrlWithParams({ path: url, source: { ...requestBody, domain } })
    );

    if (response.ok) {
      const { data } = await response.json();

      !localStorage.getItem('call_tracking_initialNumber') &&
        localStorage.setItem(
          'call_tracking_initialNumber',
          data.phone.split('-').join('')
        );
      return data.phone;
    } else {
      localStorage.setItem('call_tracking_initialNumber', DEFAULT_PHONE);
      return (
        localStorage.getItem('call_tracking_initialNumber') || DEFAULT_PHONE
      );
    }
  } catch (e) {
    localStorage.setItem('call_tracking_initialNumber', DEFAULT_PHONE);
    return localStorage.getItem('call_tracking_initialNumber') || DEFAULT_PHONE;
  }
};

export const formatPhone = (phone, format) => {
  let phoneNumber = phone;

  if (!phoneNumber) {
    phoneNumber = DEFAULT_PHONE;
  }
  phoneNumber = phoneNumber.split('-').join('');

  let result = `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(
    1,
    4
  )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;

  if (format === 'callAction') {
    result = `tel:+${phoneNumber}`;
  }
  return result;
};

export const parsePhoneNumberFromString = () => {};
