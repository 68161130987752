import React from 'react';
import CloseIcon from '../../Icons/CloseIcon';
import { formatPhone } from '../../../helpers/callTracking';
import Trustpilot from './Trustpilot';

const eventsClickPhone = () => {
  sessionStorage.setItem('modalExitIntentPopup', true);
};

const Modal = ({
  phoneNumber,
  title,
  wrapperRef,
  toggleIsShown,
  backgroundColor,
  colotTitle,
  colorText,
  callUsEventId,
  bannerId,
  popupConfig
}) => {
  const destination = title.includes('to') && title.split('to')[1];
  const { description, callUs, content, header1 } = popupConfig || {};

  const bannerDescription =
    description ||
    `We have thousands of unpublished deals that can't be displayed online as those are restricted by airlines for offline sale
  only. Call us now to save!`;

  const bannerCallUsText = callUs || 'by calling us';
  const bannerContent = content || `Get upto $300 lower than online`;
  const bannerHeader1 =
    header1 ||
    `SAVE MORE WITH ${destination ? destination.toUpperCase() : 'OUR'}
    PHONE SPECIAL DEALS`;
  const mobileBannerDescription =
    description ||
    `SPECIAL NEGOTIATED DEALS CAN BE SOLD ONLY ON PHONE (NOT
    ONLINE)`;
  return (
    <React.Fragment>
      <div id="modal_form-timeout" className="modal-block" ref={wrapperRef}>
        <div className={`modal-wrapper ${backgroundColor}`}>
          <CloseIcon handleClick={() => toggleIsShown(false)} />
          <div className="modal-text">
            <h2 className="h2 text-color">{bannerHeader1}</h2>
            <div className="wrapper-modal d-flex">
              <div className="content-popup">
                <h4 className={`h4 ${colorText}`}>{bannerContent}</h4>
                <h4 className={`h4 ${colorText}`}>{bannerCallUsText}</h4>
                <div className="phone-help d-flex">
                  <div className="call-icon"></div>

                  {/* TODO: Fix phone logic */}
                  <span className="rtap-summary">
                    <a
                      className="phone-response test-phone"
                      href={formatPhone(phoneNumber, 'callAction')}
                      onClick={eventsClickPhone}
                      id={callUsEventId}
                    >
                      {formatPhone(phoneNumber)}
                    </a>
                  </span>
                </div>
                <p className={`text-desktop ${colorText}`}>
                  {bannerDescription}
                </p>
                <p className={`text-tablet ${colorText}`}>
                  {mobileBannerDescription}
                </p>
              </div>
              <div className="img-wrapper"></div>
            </div>
            <div className="mobile-wrapper">
              <p>{mobileBannerDescription}</p>
              <div className="img-wrapper-mobile"></div>
            </div>
          </div>
        </div>
        <Trustpilot />
      </div>
      <div id="overlay-timeout" className="overlay-modal" />
    </React.Fragment>
  );
};

export default Modal;
