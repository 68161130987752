import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { buildAirportName } from '../../../../helpers/utils';
import SubmitButton from './components/SubmitButton';
import { domainCampaign, getUrlParams } from '../../../../helpers/urlParams';
import { baseApiUrl } from '../../../../configs';
import AirportInput from '../../../FindFlightsBlock/SearchForm/components/AirpotInput';
import DateInput from './components/DateInput';
import AdditionalInfo from '../../../FindFlightsBlock/SearchForm/components/AdditionalInfo';
import { exitIntentPopup } from '../../../../services/apiService';
import { formatDateApi } from '../../../../helpers/dateHelper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './reactToastifyOverride.css';

const buildAdditionalFormMessage = (passengers, cabin) => {
  const passengersCount = getPassengersCount(passengers);
  return `${passengersCount} ${
    passengersCount > 1 ? 'travelers' : 'traveler'
  }, ${cabin}`;
};

const Msg = () => (
  <>
    <div>Thank you for entering! </div>
    <p>Check out our greatest flight deals here.</p>
  </>
);

const showToast = () => {
  toast.success(<Msg />, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5000,
    pauseOnHover: false
  });
};

const getPassengersCount = passengers =>
  Object.values(passengers).reduce((a, b) => a + b, 0);

class SearchForm extends Component {
  static defaultProps = {
    big: false,
    campaign: '',
    destination: ''
  };

  static propTypes = {
    campaign: PropTypes.string,
    destination: PropTypes.string
  };

  constructor(props) {
    super(props);
    const maxPassengersCount = 9;
    this.config = {
      passengers: {
        adults: { label: 'Adults', text: '12+ years' },
        children: { label: 'Children', text: '2-11 years' },
        infants: { label: 'Infants', text: '0-2 years' }
      },
      maxPassengersCount: maxPassengersCount,
      cabinClasses: [
        {
          value: 'ECONOMY',
          label: 'Economy'
        },
        {
          value: 'PREMIUM_ECONOMY',
          label: 'Premium economy'
        },
        {
          value: 'BUSINESS',
          label: 'Business'
        },
        {
          value: 'FIRST',
          label: 'First'
        }
      ],
      errorText: {
        originSubmit: "Please provide 'From' airport",
        incorrectAirport: 'Enter the correct destination',
        destinationSubmit: "Please provide 'To' airport",
        passengersLimit: `Maximum ${maxPassengersCount} Travellers are allowed!`,
        passengersInfantsToAdults:
          'Number of infants exceeds maximum allowed per adult passenger'
      }
    };

    this.now = new Date();
    this.initStart = new Date(
      this.now.getFullYear(),
      this.now.getMonth(),
      this.now.getDate() + 7
    );
    this.initEnd = new Date(
      this.now.getFullYear(),
      this.now.getMonth(),
      this.now.getDate() + 14
    );
    this.state = {
      errors: {
        origin: '',
        destination: '',
        passengers: ''
      },
      activeTabIndex: 0,
      origin: {
        value: '',
        showSuggestions: false,
        airport: {}
      },
      destination: {
        value: '',
        showSuggestions: false,
        airport: {}
      },
      datePicker: {
        isOpen: false,
        arrival: {
          value: this.initStart
        },
        departure: {
          value: this.initEnd
        }
      },
      additionalForm: {
        isOpen: false,
        inputValue: '',
        passengers: {
          adults: 1,
          children: 0,
          infants: 0
        },
        cabinClassIndex: 0
      },
      airportsOptions: [],
      activeOptionIndex: 0,
      email: '',
      customerNumber: '',
      errorMessage: '',
      isActiveButton: false
    };
  }

  async componentDidMount() {
    const { destination } = this.props;
    this.props.onInitChangeSeach(this);
    window.addEventListener('click', this.handleOutsideClick, {
      passive: true
    });
    this.updateAdditionalInfoMessage();
    if (destination) {
      await this.getAirports(destination);
      const airport = this.state.airportsOptions[0];
      airport &&
        airport.id &&
        this.onAirportChosen('destination', airport.id, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOutsideClick);
  }

  changeSeach = state => this.setState(state);

  keyboardEventsHandler = (input, e) => {
    const { airportsOptions, activeOptionIndex } = this.state;
    const { keyCode } = e;
    switch (keyCode) {
      case 38: {
        // up arrow
        e.preventDefault();
        this.setState(({ activeOptionIndex, airportsOptions }) => ({
          activeOptionIndex:
            activeOptionIndex === 0
              ? airportsOptions.length - 1
              : activeOptionIndex - 1
        }));
        break;
      }
      case 40: {
        // down arrow
        e.preventDefault();
        this.setState(({ activeOptionIndex, airportsOptions }) => ({
          activeOptionIndex:
            activeOptionIndex === airportsOptions.length - 1
              ? 0
              : activeOptionIndex + 1
        }));
        break;
      }
      case 13: {
        // enter
        const airport = airportsOptions[activeOptionIndex];
        airport && airport.id && this.onAirportChosen(input, airport.id);
        break;
      }
      case 27: {
        // esc
        this.hideSuggestList(input);
        break;
      }
      default: {
      }
    }
  };

  onAirportChosen = (input, id) => {
    const airport =
      this.state.airportsOptions.find(el => el.id === id) ||
      this.state[input].airport;
    if (airport) {
      this.setState(
        {
          [input]: {
            value: buildAirportName(airport),
            showSuggestions: false,
            airport
          }
        },
        state => {
          this.isActiveChecked();
        }
      );
    }
    this.hideSuggestList(input);
  };

  onAirportInputChange = async (value, input) => {
    if (value.length > 2) {
      this.getAirports(value);
    } else {
      this.hideSuggestList(input);
    }
    this.setState(state => ({
      [input]: {
        ...state[input],
        value
      }
    }));
    if (!this.state[input].showSuggestions && value.length > 2) {
      this.showSuggestList(input, value);
    }
  };

  showSuggestList = input => {
    this.setState(state => ({
      [input]: {
        ...state[input],
        showSuggestions: true
      }
    }));
    this.eventHandler = e => {
      this.keyboardEventsHandler(input, e);
    };
    document.addEventListener('keydown', this.eventHandler);
  };

  hideSuggestList = input => {
    this.resetAirportsOptions();
    this.setState(state => ({
      activeOptionIndex: 0,
      [input]: {
        ...state[input],
        showSuggestions: false
      }
    }));
    document.removeEventListener('keydown', this.eventHandler);
  };

  getAirports = async searchString => {
    let airports = [];
    try {
      const response = await fetch(`${baseApiUrl}/airports?q=${searchString}`);
      if (response.ok) {
        airports = await response.json();
        this.setState({
          airportsOptions: airports.data.length ? airports.data : []
        });
      } else {
        this.resetAirportsOptions();
      }
    } catch (e) {
      this.resetAirportsOptions();
    }
  };

  resetAirportsOptions = () =>
    this.setState({
      airportsOptions: []
    });

  setActiveOptionIndex = index =>
    this.setState({
      activeOptionIndex: index
    });

  onAirportFieldFocus = (value, input) => {
    this.getAirports(value);
    value.length > 2 && this.showSuggestList(input);
    this.setError(input, '');
  };

  onAirportFieldBlur = (input, value) => {
    const {
      state: { airportsOptions, activeOptionIndex },
      config: { errorText }
    } = this;
    const airport =
      airportsOptions[activeOptionIndex] || this.state[input].airport;

    let textError = '';

    if (airport && airport.id) {
      this.onAirportChosen(input, airport.id);
    }
    if (value && (!airport || !airport.id)) {
      textError = errorText.incorrectAirport;
      this.onAirportInputChange('', input);
    }
    this.hideSuggestList(input);
    this.setError(input, textError);
  };

  switchAirportsData = () => {
    const { origin, destination, datePicker, additionalForm } = this.state;
    this.setState({
      origin: destination,
      destination: origin,
      datePicker: {
        ...datePicker,
        isOpen: false
      },
      additionalForm: {
        ...additionalForm,
        isOpen: false
      }
    });
    this.setError('origin', '');
    this.setError('destination', '');
    this.setError('passengers', '');
    this.hideSuggestList('origin');
    this.hideSuggestList('destination');
  };

  setError = (input, text) =>
    this.setState(({ errors }) => ({ errors: { ...errors, [input]: text } }));

  onDateChange = (value, isRange) => {
    let result = {
      arrival: {
        value
      },
      departure: {
        value
      }
    };
    if (isRange) {
      result = {
        arrival: {
          value: value[0]
        },
        departure: {
          value: value[1]
        }
      };
    }
    this.setState({
      datePicker: {
        ...this.state.datePicker,
        isOpen: false,
        ...result
      }
    });
  };

  buildInputValueString = value => {
    const departureDate = new Date(value[1].toLocaleDateString());
    const departureDateIfOneWay = new Date(
      departureDate.getTime() + 86400000 * 7
    );
    const formatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    return value[0].toLocaleDateString() === value[1].toLocaleDateString()
      ? `${value[0].toLocaleDateString(
          undefined,
          formatOptions
        )} - ${departureDateIfOneWay.toLocaleDateString(
          undefined,
          formatOptions
        )}`
      : `${value[0].toLocaleDateString(
          undefined,
          formatOptions
        )} - ${value[1].toLocaleDateString(undefined, formatOptions)}`;
  };

  switchCalendar = () => {
    this.setState(({ datePicker, additionalForm }) => ({
      datePicker: {
        ...datePicker,
        isOpen: !datePicker.isOpen
      },
      additionalForm: {
        ...additionalForm,
        isOpen: false
      }
    }));
  };

  switchAdditionalForm = () => {
    this.setState(({ additionalForm, datePicker }) => ({
      datePicker: {
        ...datePicker,
        isOpen: false
      },
      additionalForm: {
        ...additionalForm,
        isOpen: !additionalForm.isOpen
      }
    }));
  };

  onCabinChange = id =>
    this.setState(
      ({ additionalForm }) => ({
        additionalForm: { ...additionalForm, cabinClassIndex: id }
      }),
      () => this.updateAdditionalInfoMessage()
    );

  handleOutsideClick = e => {
    const current = this.formRef;

    if (current && !current.contains(e.target)) {
      this.setState(({ datePicker, additionalForm }) => ({
        datePicker: {
          ...datePicker,
          isOpen: false
        },
        additionalForm: {
          ...additionalForm,
          isOpen: false
        }
      }));
    }
  };

  onPassengerChange = (value, id) => {
    const {
      config: { maxPassengersCount },
      state: { additionalForm }
    } = this;
    const passengers = { ...additionalForm.passengers };
    const newCount = passengers[id] + value;
    const minCount = id === 'adults' ? 1 : 0;
    if (newCount < minCount) {
      return;
    }
    passengers[id] = newCount < minCount ? minCount : newCount;
    const isDecrement = value < 0;
    const isValidCount = getPassengersCount(passengers) <= maxPassengersCount;
    const isInfantsLessAdults = passengers.infants <= passengers.adults;

    if (
      (isDecrement && isInfantsLessAdults) ||
      (isValidCount && isInfantsLessAdults)
    ) {
      this.setState(
        {
          additionalForm: {
            ...this.state.additionalForm,
            passengers: {
              ...passengers
            }
          }
        },
        () => this.updateAdditionalInfoMessage()
      );
    }
    switch (false) {
      case isInfantsLessAdults: {
        this.setError(
          'passengers',
          this.config.errorText.passengersInfantsToAdults
        );
        break;
      }
      case !isDecrement: {
        this.setError('passengers', '');
        break;
      }
      case isValidCount: {
        this.setError('passengers', this.config.errorText.passengersLimit);
        break;
      }
      default: {
        this.setError('passengers', '');
        break;
      }
    }
  };

  updateAdditionalInfoMessage = () => {
    const {
      config: { cabinClasses },
      state: {
        additionalForm: { passengers, cabinClassIndex }
      }
    } = this;

    const inputValue = buildAdditionalFormMessage(
      passengers,
      cabinClasses[cabinClassIndex].label
    );
    this.setState(({ additionalForm }) => ({
      additionalForm: { ...additionalForm, inputValue }
    }));
  };

  inputChange = e => {
    const trimmedInput = e.target.value.replace(/\s/g, '');
    if (/^[^@]+@[^@]+\.[^@]+$/.test(trimmedInput)) {
      this.setState({ email: e.target.value }, state => {
        this.isActiveChecked();
      });
      return;
    }
    this.setState({
      isActiveButton: false
    });
  };

  customerNumberChange = e => {
    const inputValue = e.target.value;
    let formattedValue = inputValue.trim();

    // check if the minimum number of characters is 12
    if (formattedValue.length >= 12 && !/[^0-9+]/.test(inputValue)) {
      this.setState(
        { customerNumber: formattedValue, errorMessage: '' },
        () => {
          this.isActiveChecked();
        }
      );
    } else {
      this.setState({ customerNumber: formattedValue, errorMessage: ' ' });
    }
  };

  handleKeyUp = e => {
    const inputValue = e.target.value.trim();
    let errorMessage = '';

    if (!inputValue.startsWith('+')) {
      errorMessage = 'Please add your country code';
    } else if (/[^0-9+]/.test(inputValue) || inputValue.length > 14) {
      errorMessage = 'Please add a valid phone number';
    } else if (inputValue.length > 0 && inputValue.length < 12) {
      errorMessage = 'Phone number is too short';
    }

    this.setState({ errorMessage });
  };

  isActiveChecked = () => {
    if (
      this.state.origin.value &&
      this.state.destination.value &&
      this.state.email &&
      this.state.customerNumber
    ) {
      this.setState({
        isActiveButton: true
      });
    } else {
      this.setState({
        isActiveButton: false
      });
    }
  };

  onSubmit = () => {
    const {
      config: { errorText },
      state: { origin, destination }
    } = this;
    if (!origin.airport.id) {
      this.setError('origin', errorText.originSubmit);
    }
    if (!destination.airport.id) {
      this.setError('destination', errorText.destinationSubmit);
    }
    if (origin.airport.id && destination.airport.id) {
      this.submit();
      showToast();
    }
  };

  submit = () => {
    const {
      state: {
        origin,
        destination,
        datePicker,
        additionalForm,
        email,
        customerNumber
      }
    } = this;
    const { adults, children, infants } = additionalForm.passengers;
    exitIntentPopup({
      adults,
      children,
      infants,
      origin: origin.airport.code,
      destination: destination.airport.code,
      arrivalDate: formatDateApi(datePicker.departure.value),
      departureDate: formatDateApi(datePicker.arrival.value),
      source: getUrlParams().campaign ? getUrlParams().campaign : 'Flykart',
      email,
      customerNumber
    });
    this.props.onInitToggleModal(false);
    this.props.toggleIsShownExitModal(false);
    this.props.hidePopup();
  };

  render() {
    const {
      config: { cabinClasses, passengers },
      now,
      state: {
        errors,
        origin,
        destination,
        airportsOptions,
        activeOptionIndex,
        datePicker,
        additionalForm
      }
    } = this;
    return (
      <div className="search d-flex" ref={ref => (this.formRef = ref)}>
        <AirportInput
          className="origin"
          options={airportsOptions}
          onAirportChosen={this.onAirportChosen}
          onAirportInputChange={this.onAirportInputChange}
          value={origin.value}
          showSuggestions={origin.showSuggestions}
          activeOptionIndex={activeOptionIndex}
          setActiveOptionIndex={this.setActiveOptionIndex}
          onFocus={this.onAirportFieldFocus}
          onBlur={this.onAirportFieldBlur}
          switchAirportsData={this.switchAirportsData}
          error={errors.origin}
        />
        <AirportInput
          className="destination"
          options={airportsOptions}
          onAirportChosen={this.onAirportChosen}
          onAirportInputChange={this.onAirportInputChange}
          value={destination.value}
          showSuggestions={destination.showSuggestions}
          activeOptionIndex={activeOptionIndex}
          setActiveOptionIndex={this.setActiveOptionIndex}
          onFocus={this.onAirportFieldFocus}
          onBlur={this.onAirportFieldBlur}
          error={errors.destination}
        />
        <DateInput
          isDateRange
          onDateChange={this.onDateChange}
          dateNow={now}
          isOpen={datePicker.isOpen}
          inputValue={this.buildInputValueString([
            datePicker.arrival.value,
            datePicker.departure.value
          ])}
          switchCalendar={this.switchCalendar}
          value={[datePicker.arrival.value, datePicker.departure.value]}
        />
        <AdditionalInfo
          cabinClasses={cabinClasses}
          additionalForm={additionalForm}
          onCabinChange={this.onCabinChange}
          switchAdditionalForm={this.switchAdditionalForm}
          passengers={passengers}
          onPassengerChange={this.onPassengerChange}
          error={errors.passengers}
        />
        <div className="flex-wrapper search-input d-flex">
          <input
            className="phone"
            type="tel"
            aria-label="Enter phone number with country code"
            placeholder="+X XXX XXXX XXXX"
            onChange={this.customerNumberChange}
            onBlur={this.handleKeyUp}
          />
          {this.state.errorMessage !== '' && (
            <div className="error-message">{this.state.errorMessage}</div>
          )}
          <input
            className="email"
            type="text"
            placeholder="example@email.com"
            onChange={this.inputChange}
          />
        </div>
        <div className="flex-wrapper search-input d-flex">
          <SubmitButton
            isActive={
              this.state.isActiveButton && this.state.errorMessage === ''
            }
            type="button"
            submit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

export default SearchForm;
