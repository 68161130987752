import React, { useState } from 'react';
import { createUrlWithParams } from '../helpers/urlParams';

const LinkUrl = ({
  path,
  target = '_self',
  className,
  onClick,
  children,
  rel = ''
}) => {
  const [href, setHref] = useState('');

  React.useEffect(() => {
    setHref(createUrlWithParams({ path }));
  }, []);
  return (
    <a
      href={href}
      rel={rel}
      target={target}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default LinkUrl;
