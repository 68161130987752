import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from '../../../services/stringService';
import {
  domainCampaign,
  isOffline,
  getUrlParams
} from '../../../helpers/urlParams';
import { modalCallUsTimeout } from '../../../configs';
import { isDelayed, markAsOpened } from '../../../helpers/defaultPopup';
import {
  getMobileOperatingSystem,
  operatingSystems
} from '../../../helpers/utils';
import Modal from './Modal';

import './index.css';

let timeOut;

function getFullTitle(slug) {
  if (!slug || !slug.split('/')[1]) {
    return '';
  }
  const fullTitle =
    slug && slug !== ''
      ? slug
          .split('/')[1]
          .split('-')
          .map(word => {
            const [first, ...rest] = word.split('');

            return [first, ...rest].join('');
          })
          .join(' ')
      : ' flights';
  const [, , destinationTitle] = fullTitle.split(' ');
  const capitalizedTitle = capitalize(destinationTitle);

  return ` to ${capitalizedTitle}`;
}

const domain = domainCampaign();
const { utm_source } = getUrlParams();
const offlineCampaign = isOffline();

const CallUs = ({
  pageData,
  phoneNumber,
  toggleModals,
  showModal,
  isBannersShown,
  defaultPopup
}) => {
  const [isShown, toggleIsShown] = React.useState(false);
  const wrapperRef = React.useRef(null);

  let operatingSystem;

  const hidePopup = () => {
    document.documentElement.style.position = 'relative';
    document.documentElement.style.overflow = 'auto';
  };
  const showPopup = () => {
    if (!isBannersShown) {
      return;
    }
    if (operatingSystem === operatingSystems.IOS) {
      document.documentElement.style.position = 'fixed';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  };

  const OpenModal = () => {
    document.addEventListener('click', handleClickOutside, {
      passive: true,
      capture: false
    });
    if (!isDelayed() && offlineCampaign) {
      toggleIsShown(!isShown);
      showPopup();
    }

    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'Marketing', {
        event_category: domain,
        event_label: 'DefaultPopUpOpened',
        event_source: utm_source || 'Flykart'
      });
    }
  };

  const timeOutModals = (toggleModals, showModal) => {
    if (!toggleModals && showModal) {
      timeOut = setTimeout(OpenModal, modalCallUsTimeout);
    } else {
      clearTimeout(timeOut);
    }
  };

  React.useEffect(() => {
    operatingSystem = getMobileOperatingSystem(window);
    timeOutModals(toggleModals, showModal);
  }, [toggleModals, showModal]);

  React.useEffect(() => {
    if (isShown) {
      markAsOpened();

      return () => {
        hidePopup();
        document.removeEventListener('click', handleClickOutside, false);
      };
    }
  }, [isShown]);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      toggleIsShown(false);
    }
  };

  const openDefaultModal = defaultPopup ? defaultPopup.showBanner : true;

  return isShown &&
    !isDelayed() &&
    !toggleModals &&
    isBannersShown &&
    openDefaultModal ? (
    <Modal
      phoneNumber={phoneNumber}
      title={getFullTitle(pageData.slug)}
      toggleIsShown={toggleIsShown}
      wrapperRef={wrapperRef}
      backgroundColor="background-light"
      colotTitle="color-darker"
      colorText="color-dark"
      callUsEventId="call-us-phone-default-popup"
      bannerId="default-popup"
      popupConfig={defaultPopup}
    />
  ) : null;
};

export default CallUs;

CallUs.propTypes = {
  pageData: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string,
  toggleModals: PropTypes.bool
};

CallUs.defaultProps = {
  phoneNumber: '',
  toggleModals: false
};
