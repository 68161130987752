import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './DateInput.css';

const DateInput = ({
  className,
  inputValue,
  isOpen,
  dateNow,
  onDateChange,
  value,
  isDateRange,
  switchCalendar
}) => {
  return (
    <div className={`search-input ${className}`}>
      <input type="text" readOnly value={inputValue} onClick={switchCalendar} />
      <DatePicker
        className={isOpen ? 'show-date-picker' : 'hide-date-picker'}
        minDate={dateNow}
        format="MM/dd/yy"
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date"
        onChange={value => onDateChange(value, isDateRange)}
        value={value}
        yearAriaLabel="Year"
        calendarIcon={null}
        clearIcon={null}
        locale="en-EN"
        selectRange={isDateRange}
      />
    </div>
  );
};

export default DateInput;

DateInput.propTypes = {
  className: PropTypes.string,
  dateNow: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  isDateRange: PropTypes.bool,
  isOpen: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  switchCalendar: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

DateInput.defaultProps = {
  className: '',
  isDateRange: false,
  isOpen: false
};
