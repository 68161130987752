import { baseApiUrl, baseApiIBEURL } from '../configs';

export const subscribeToNewsletter = ({ email, contactNumber, source }) => {
  fetch(`${baseApiUrl}/subscription`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, contactNumber, source })
  });
};

export const exitIntentPopup = postInfoExitIntentPopup => {
  fetch(`${baseApiUrl}/emailCampaign`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postInfoExitIntentPopup)
  });
};

export const winATripSave = ({
  name,
  email,
  phone,
  originAirport,
  tripsInYear,
  source
}) => {
  fetch(`${baseApiUrl}/subscription`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      source,
      name: name.value,
      email: email.value,
      contactNumber: phone.value,
      preferredOriginAirport: originAirport.value,
      tripsInYear: tripsInYear.value
    })
  });
};

export const GetCallBackFormSave = ({
  name,
  email,
  contactNumber,
  bestTimeToCall,
  yourMessage,
  source
}) => {
  fetch(`${baseApiUrl}/subscription`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: name.value,
      email: email.value,
      contactNumber: contactNumber.value,
      bestTimeToCall: bestTimeToCall.value,
      message: yourMessage.value,
      source: source
    })
  });
};

export const sendVacationOptions = data => {
  fetch(`${baseApiUrl}/vacation`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const GetGroupPageSearchFormSave = postInfoGroupForm => {
  fetch(`${baseApiUrl}/groupTravel`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postInfoGroupForm)
  });
};

export const GetCheapestInitLoad = async data => {
  let result = [];
  try {
    let response = await fetch(
      `${baseApiIBEURL}/flights/calendar-cheapest-day`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );
    result = await response.json();
  } catch (e) {
    return result;
  }
  return result.data;
};

export const GetCheapestRecommendations = async data => {
  let result = [];
  try {
    let response = await fetch(
      `${baseApiIBEURL}/flights/cheapest-recommendations`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );
    result = await response.json();
  } catch (e) {
    return result;
  }
  return result.data;
};
