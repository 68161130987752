import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CabinClass from './CabinClass';
import PassengersInput from './PassengersInput';

import './AdditionalInfo.css';

export default class AdditionalInfo extends Component {
  static propTypes = {
    additionalForm: PropTypes.object.isRequired,
    cabinClasses: PropTypes.array.isRequired,
    className: PropTypes.string,
    onCabinChange: PropTypes.func.isRequired,
    onPassengerChange: PropTypes.func.isRequired,
    passengers: PropTypes.object.isRequired,
    switchAdditionalForm: PropTypes.func.isRequired
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.additionalForm.inputValue ===
        nextProps.additionalForm.inputValue &&
      this.props.error === nextProps.error &&
      this.props.additionalForm.isOpen === nextProps.additionalForm.isOpen
    ) {
      return false;
    }

    return true;
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      className,
      additionalForm,
      cabinClasses,
      onCabinChange,
      switchAdditionalForm,
      passengers,
      error,
      onPassengerChange,
      groupTravelView,
      onLinkClick
    } = this.props;
    return (
      <div className={`search-input ${className} additional-info`}>
        <input
          type="text"
          readOnly
          value={additionalForm.inputValue}
          onClick={switchAdditionalForm}
        />
        {additionalForm.isOpen && (
          <div className="additional-form">
            <div className="travelersWrapper">
              <span className="title-add">Travelers</span>
              <div className="passenger-select-wrapper">
                {Object.keys(passengers).map(passenger => (
                  <PassengersInput
                    key={passenger}
                    passenger={{ ...passengers[passenger], id: passenger }}
                    onPassengerChange={onPassengerChange}
                    count={additionalForm.passengers[passenger]}
                  />
                ))}
              </div>
              <span className="title-add">Cabin class</span>
              <div className="passenger-select-wrapper">
                {cabinClasses.map((cabin, i) => (
                  <CabinClass
                    cabin={cabin}
                    checked={additionalForm.cabinClassIndex === i}
                    key={cabin.value}
                    id={i}
                    onCabinChange={onCabinChange}
                  />
                ))}
              </div>
            </div>

            {!groupTravelView && (
              <span className="group-travel-link" onClick={onLinkClick}>
                5 or more travelers, click here for best deals
              </span>
            )}
            <label className="traveler-error">{error}</label>
            <button id="switchAdditionalForm" onClick={switchAdditionalForm}>
              Done
            </button>
          </div>
        )}
      </div>
    );
  }
}
