import PropTypes from 'prop-types';
import React from 'react';
import { formatPhone } from '../../helpers/callTracking';
import { useWindowSize } from '../../helpers/useWindowSize';
import moment from 'moment';
import './styles.css';

const BannerFooter = ({
  phoneNumber,
  onInitToggleCallUsModal,
  mobileFooterEl,
  footerBanner
}) => {
  const [width] = useWindowSize();

  const {
    content,
    callUsText,
    validityDays,
    validityText,
    showValidity,
    callActionType,
    device
  } = footerBanner;

  const eventsClickPhone = () => {
    sessionStorage.setItem('modalExitIntentPopup', true);
  };
  const toggleCallUsModal = () => {
    onInitToggleCallUsModal(true);
  };

  const callActionTypeHandler = () => {
    // if we are in mobile view then device is undefined
    if (device && callActionType === 'dialer') {
      eventsClickPhone();
      window.location.href = formatPhone(phoneNumber, 'callAction');
    } else if (device && callActionType === 'popup') {
      toggleCallUsModal();
    } else if (!device && width < 760) {
      // default for mobile- dialer
      eventsClickPhone();
      window.location.href = formatPhone(phoneNumber, 'callAction');
    } else {
      // default for desktop- call modal
      toggleCallUsModal();
    }
  };

  return (
    <>
      {width >= 760 && (
        <div className="footer" onClick={callActionTypeHandler}>
          <div className="container callus-desktop-footer-text">
            <span>
              {`${callUsText || 'Call Us'} ${content ||
                'To Get up to $300 lower than online'}`.toUpperCase()}
            </span>
            <span style={{ paddingLeft: '4%' }}>
              {showValidity
                ? `${validityText} ${
                    validityDays >= 0
                      ? moment()
                          .add(validityDays, 'days')
                          .format('Do MMM, YY')
                      : ''
                  }`
                : ''}
            </span>
          </div>
        </div>
      )}
      {width < 760 && (
        <>
          <div
            ref={mobileFooterEl}
            className="footer mobile-footer d-flex"
            id="call-us-phone-footer-mobile"
          >
            <div className="call-us-btn d-flex">
              <span onClick={callActionTypeHandler}>
                <div className="d-flex call-us-container">
                  <svg
                    width="25"
                    height="22.5"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="buzzing-phone"
                  >
                    <path
                      d="M16.875 18C14.8417 18 12.8208 17.5 10.8125 16.5C8.80417 15.5 7 14.2 5.4 12.6C3.8 11 2.5 9.19583 1.5 7.1875C0.5 5.17917 0 3.15833 0 1.125C0 0.808333 0.108333 0.541667 0.325 0.325C0.541667 0.108333 0.808333 0 1.125 0H4.625C4.85833 0 5.0625 0.0791666 5.2375 0.2375C5.4125 0.395833 5.525 0.608333 5.575 0.875L6.25 4.025C6.28333 4.25833 6.27917 4.47083 6.2375 4.6625C6.19583 4.85417 6.10833 5.01667 5.975 5.15L3.475 7.675C4.40833 9.225 5.45417 10.575 6.6125 11.725C7.77083 12.875 9.08333 13.85 10.55 14.65L12.925 12.2C13.0917 12.0167 13.2833 11.8875 13.5 11.8125C13.7167 11.7375 13.9333 11.725 14.15 11.775L17.125 12.425C17.375 12.475 17.5833 12.6 17.75 12.8C17.9167 13 18 13.2333 18 13.5V16.875C18 17.1917 17.8917 17.4583 17.675 17.675C17.4583 17.8917 17.1917 18 16.875 18Z"
                      fill="black"
                    ></path>
                  </svg>
                  <p className="call-us-height callUs-text">
                    {callUsText || 'Call Us'}
                  </p>
                  {showValidity ? (
                    <p className="validity-text">
                      {`${validityText} ${
                        validityDays >= 0
                          ? moment()
                              .add(validityDays, 'days')
                              .format('Do MMM, YY')
                          : ''
                      }
                      `}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="call-us-text">
                  <p>{content || 'Save upto $300 on phone'}</p>
                </div>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BannerFooter;

BannerFooter.propTypes = {
  phoneNumber: PropTypes.string.isRequired
};

// -------------------------- REMOVED CODE --------------------------

// const PhoneCallIcon = () => (
//   <svg
//     class="w-20 h-20 mr-2"
//     viewBox="0 0 18 15"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.39533 3.76955V0H9.60467V3.76955H8.39533ZM13.6358 6.01524L12.7691 5.133L15.4498 2.50635L16.2359 3.38858L13.6358 6.01524ZM4.44484 6.01524L1.74399 3.38858L2.61068 2.50635L5.23091 5.133L4.44484 6.01524ZM9 8.08047C10.6931 8.08047 12.3122 8.41799 13.8575 9.09303C15.4028 9.76808 16.7263 10.7472 17.8281 12.0305C17.9356 12.1641 17.9928 12.3112 17.9995 12.4716C18.0062 12.632 17.9491 12.7724 17.8281 12.8927L15.9335 14.7774C15.826 14.8844 15.6715 14.9445 15.4699 14.9579C15.2684 14.9713 15.1071 14.9312 14.9862 14.8376L12.6885 13.1333C12.6079 13.0664 12.5474 12.9929 12.5071 12.9127C12.4668 12.8325 12.4466 12.7456 12.4466 12.6521V9.86499C11.8822 9.65112 11.3078 9.50073 10.7233 9.41385C10.1388 9.32696 9.56436 9.28352 9 9.28352C8.43564 9.28352 7.86121 9.32696 7.2767 9.41385C6.69218 9.50073 6.11775 9.65112 5.55339 9.86499V12.6521C5.55339 12.7456 5.53324 12.8325 5.49293 12.9127C5.45262 12.9929 5.39215 13.0664 5.31153 13.1333L3.01379 14.8376C2.85255 14.9579 2.68794 15.0114 2.51998 14.998C2.35202 14.9846 2.20085 14.9111 2.06648 14.7774L0.171853 12.8927C0.0509194 12.7724 -0.00618812 12.632 0.00053041 12.4716C0.00724894 12.3112 0.0643564 12.1641 0.171853 12.0305C1.27369 10.7472 2.59724 9.76808 4.1425 9.09303C5.68777 8.41799 7.30693 8.08047 9 8.08047Z"
//       fill="white"
//     />
//   </svg>
// );

// const NoHoldIcon = () => (
//   <svg
//     class="w-20 h-20 mr-2"
//     viewBox="0 0 15 15"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M7.5625 15C6.52083 15 5.54167 14.8021 4.625 14.4062C3.70833 14.0104 2.90625 13.4722 2.21875 12.7917C1.53125 12.1111 0.989583 11.3125 0.59375 10.3958C0.197917 9.47917 0 8.5 0 7.45833C0 6.41667 0.197917 5.44444 0.59375 4.54167C0.989583 3.63889 1.53125 2.85069 2.21875 2.17708C2.90625 1.50347 3.70833 0.972222 4.625 0.583333C5.54167 0.194444 6.52083 0 7.5625 0C8.67361 0 9.72569 0.243056 10.7188 0.729167C11.7118 1.21528 12.5694 1.88194 13.2917 2.72917V0.520833H14.5417V4.85417H10.1875V3.60417H12.375C11.7639 2.89583 11.0451 2.32639 10.2188 1.89583C9.39236 1.46528 8.50694 1.25 7.5625 1.25C5.82639 1.25 4.34028 1.84375 3.10417 3.03125C1.86806 4.21875 1.25 5.67361 1.25 7.39583C1.25 9.15972 1.86111 10.6597 3.08333 11.8958C4.30556 13.1319 5.79861 13.75 7.5625 13.75C9.29861 13.75 10.7639 13.1389 11.9583 11.9167C13.1528 10.6944 13.75 9.21528 13.75 7.47917H15C15 9.5625 14.2778 11.3368 12.8333 12.8021C11.3889 14.2674 9.63194 15 7.5625 15ZM10.1042 10.8958L6.89583 7.72917V3.27083H8.14583V7.20833L11 10L10.1042 10.8958Z"
//       fill="white"
//     />
//   </svg>
// );

// const PhoneIcon = () => (
//   <svg
//     class="w-20 h-20"
//     viewBox="0 0 18 18"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M16.875 18C14.8417 18 12.8208 17.5 10.8125 16.5C8.80417 15.5 7 14.2 5.4 12.6C3.8 11 2.5 9.19583 1.5 7.1875C0.5 5.17917 0 3.15833 0 1.125C0 0.808333 0.108333 0.541667 0.325 0.325C0.541667 0.108333 0.808333 0 1.125 0H4.625C4.85833 0 5.0625 0.0791666 5.2375 0.2375C5.4125 0.395833 5.525 0.608333 5.575 0.875L6.25 4.025C6.28333 4.25833 6.27917 4.47083 6.2375 4.6625C6.19583 4.85417 6.10833 5.01667 5.975 5.15L3.475 7.675C4.40833 9.225 5.45417 10.575 6.6125 11.725C7.77083 12.875 9.08333 13.85 10.55 14.65L12.925 12.2C13.0917 12.0167 13.2833 11.8875 13.5 11.8125C13.7167 11.7375 13.9333 11.725 14.15 11.775L17.125 12.425C17.375 12.475 17.5833 12.6 17.75 12.8C17.9167 13 18 13.2333 18 13.5V16.875C18 17.1917 17.8917 17.4583 17.675 17.675C17.4583 17.8917 17.1917 18 16.875 18Z"
//       fill="white"
//     />
//   </svg>
// );

// use case for 3 links with new footer
// const newContentLinks = [
//   'flights/cheap-flight-tickets',
//   'flights/cheap-flights-to-south-west-usa',
//   'flights/cheap-flights-from-united-state'
// ];
// const currentUrl = typeof window !== 'undefined' && window.location.pathname;
// const reservationsFooter = () => (
//   <div className="reservations-footer">
//     <div className="reservations-customer-service">
//       <h6>CUSTOMER SERVICE</h6>
//       <div class="reservations-service-items">
//         <div class="service-item">
//           <PhoneCallIcon />
//           <span>24/7 Helpline</span>
//         </div>
//         <div class="service-item">
//           <NoHoldIcon />
//           <span> No Hold Time</span>
//         </div>
//       </div>
//     </div>
//     <div className="reservation-phone-box">
//       <a
//         className="reservations-phone-number"
//         href={formatPhone('16469330907', 'callAction')}
//       >
//         <PhoneIcon /> <span>{formatPhone('16469330907')}</span>
//       </a>
//     </div>
//   </div>
// );
