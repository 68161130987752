import React from 'react';
import { baseApiUrl } from '../../configs';
import { markAsClosed, wasClosed } from '../../helpers/banner';

import './Banner.css';

function splitToComponents(message) {
  if (message.indexOf('[') === -1 || message.indexOf(']') === -1) {
    return [message];
  }
  const linkIndexes = [];
  let openIndex = null;

  for (let i = 0; i < message.length; i++) {
    if (openIndex !== null && message[i] === ']') {
      linkIndexes.push([openIndex, i]);
      openIndex = null;
    }

    if (openIndex === null && message[i] === '[') {
      openIndex = i;
    }
  }
  const components = [];
  let startIndex = 0;

  for (let i = 0; i < linkIndexes.length; i++) {
    const [start, end] = linkIndexes[i];
    const stringComponent = message.slice(startIndex, start);
    components.push(stringComponent);
    startIndex = end + 1;
    const link = message.slice(start + 1, end);
    const [text, href] = link.split('::');

    components.push(
      <a className="banner-link" target="_blank" href={href}>
        {text}
      </a>
    );
  }
  components.push(message.slice(startIndex));
  return components;
}

export default class Banner extends React.Component {
  state = {
    messages: null,
    isClosed: !!wasClosed()
  };

  async componentDidMount() {
    const response = await fetch(`${baseApiUrl}/banner/list`);
    const messages = await response.json();

    if (Array.isArray(messages)) {
      this.setState({ messages });
    }
  }

  close() {
    markAsClosed();
    this.setState({ isClosed: true });
  }

  render() {
    const { isClosed, messages } = this.state;

    if (isClosed || !messages || messages.length === 0) {
      return null;
    }

    return (
      <div className="banner_container">
        <ul>
          {messages.map(msg => (
            <li className="banner__container--item" key={msg.id}>
              {splitToComponents(msg.message)}
            </li>
          ))}
        </ul>
        <button
          className="banner__container--close-btn"
          onClick={() => this.close(true)}
        >
          x
        </button>
      </div>
    );
  }
}
