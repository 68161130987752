import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Image from '../../components/Image/Image';
import Image404 from '../image';
import { useWindowSize } from '../../helpers/useWindowSize';
import SearchForm from './SearchForm';
import { baseIBEURL } from '../../configs';
import { domainCampaign, isOnline } from '../../helpers/urlParams';
import { formatPhone } from '../../helpers/callTracking';
import { mobileWidth } from '../../configs/constants';

import AgentImg from '../Image/AgentImg';
import TenYearImage from '../Image/TenYearImage';
import LiveImg from '../Image/LiveImg';
import ReturnImg from '../Image/ReturnImg';

import './FindFlightsBlock.css';
import '../../styles/react-calendar.css';
import CachedLastFlightSearchDetails from '../../pages/CachedLastFlightSearchDetails';
import { PHONE_NUMBER } from '../../constants/phoneNumber';

const FindFlightsBlock = ({
  destinationIATA,
  originIATA,
  phoneNumber,
  title,
  updatePhone,
  getInfoSearch,
  image,
  isErrorPage,
  isBannersShown,
  pageData,
  homePageBanner = {}
}) => {
  const [width] = useWindowSize();
  const onlineCampaign = isOnline();

  const [searchedFlightDetails_1, setSearchedFlightDetails_1] = useState('');
  const [searchedFlightDetails_2, setSearchedFlightDetails_2] = useState('');

  useEffect(() => {
    setSearchedFlightDetails_1(
      JSON.parse(localStorage.getItem('searched_flight_details_1'))?.[
        domainCampaign()
      ]
    );
    setSearchedFlightDetails_2(
      JSON.parse(localStorage.getItem('searched_flight_details_2'))?.[
        domainCampaign()
      ]
    );
  }, []);

  const eventsClickPhone = () => {
    sessionStorage.setItem('modalExitIntentPopup', true);
  };

  const bannerDetails = {
    header1: homePageBanner.header1 || 'Call us for unpublished deals',
    header2: homePageBanner.header2 || 'Phone-only fare may save you more',
    callUsText: homePageBanner.callUs || 'Call our travel experts at:',
    showBanner:
      homePageBanner && homePageBanner.showBanner !== undefined
        ? homePageBanner.showBanner
        : true
  };

  return (
    <div className="find-flights-block">
      {width > mobileWidth && (
        <div className="background">
          {image && !isErrorPage ? (
            <Image alt="flight-background" image={image} />
          ) : (
            <Image404
              alt="flight-background"
              filename="images/flight-background.jpg"
              type="flight-background"
            />
          )}
          <div className="background-gradient" />
        </div>
      )}
      <div className="wrapper" style={{ position: 'relative' }}>
        <h1 className="title">{title}</h1>
        <div
          className={`content d-flex ${width >= 1024 &&
            onlineCampaign &&
            'campaign-tablet'}`}
        >
          <div className="search-form-wrapper">
            <SearchForm
              baseIBEURL={baseIBEURL}
              big={!!(width >= 1024 && onlineCampaign)}
              destination={destinationIATA}
              origin={originIATA}
              updatePhone={updatePhone}
              phoneNumber={
                pageData.tag &&
                pageData.tag.fixedNumber &&
                PHONE_NUMBER[pageData.tag.continent]
              }
              getInfoSearch={getInfoSearch}
              isBusinessClass={
                pageData.tag && pageData.tag.category === 'business-class'
              }
            />
            {searchedFlightDetails_1 || searchedFlightDetails_2 ? (
              <>
                <CachedLastFlightSearchDetails />
              </>
            ) : (
              ''
            )}
          </div>
          {width > mobileWidth && (
            <div className="information-area d-flex">
              {!onlineCampaign &&
                isBannersShown &&
                bannerDetails &&
                bannerDetails.showBanner && (
                  <div
                    className={
                      // class => "call-us-deals-business-page" for only business pages
                      pageData.tag && pageData.tag.category === 'business-class'
                        ? 'call-us-deals d-flex call-us-deals-business-page'
                        : 'call-us-deals d-flex'
                    }
                  >
                    <div className="block">
                      <h3>
                        {bannerDetails.header1}

                        {title.includes('to') &&
                          title
                            .split(' ')
                            .slice(3)
                            .join(' ')}
                      </h3>
                      <p>{bannerDetails.header2}</p>
                      <p>{bannerDetails.callUsText}</p>
                      <div className="phone d-flex">
                        <a
                          href={formatPhone(phoneNumber, 'callAction')}
                          onClick={eventsClickPhone}
                          className="phone-wrapper"
                          id="call-us-phone-home-desktop-icon"
                        >
                          <img
                            className="phone-icon"
                            src="/../../images/callIcon.svg"
                            alt="call-icon"
                          />
                          <span
                            className="call-us-phone"
                            id="call-us-phone-home-desktop"
                          >
                            {formatPhone(phoneNumber)}
                          </span>
                        </a>
                      </div>
                    </div>
                    {/* for desktop and tab only*/}
                    {pageData && pageData.image === 'business-class' ? (
                      <div className="businessImage" />
                    ) : (
                      <div className="agent-img">
                        <AgentImg alt="support" />
                      </div>
                    )}
                  </div>
                )}
              {((width > 1024 && !onlineCampaign) || onlineCampaign) && (
                <div className="ourProgress-excellence d-flex">
                  <div style={{ width: 130 }}>
                    <TenYearImage alt="10years" />
                  </div>
                  <div style={{ width: 130 }}>
                    <ReturnImg alt="80return" />
                  </div>
                  <div style={{ width: 130 }}>
                    <LiveImg alt="live7" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FindFlightsBlock.propTypes = {
  bgImageSrc: PropTypes.string,
  destinationIATA: PropTypes.string,
  originIATA: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  title: PropTypes.string,
  updatePhone: PropTypes.func.isRequired,
  isBannersShown: PropTypes.bool
};

FindFlightsBlock.defaultProps = {
  bgImageSrc: 'flight-background.jpg',
  destinationIATA: '',
  originIATA: '',
  title: 'Find Cheap Flights'
};

export default FindFlightsBlock;
