import PropTypes from 'prop-types';
import React from 'react';

import AirportInput from '../AirpotInput';
import DateInput from '../DateInput';
import AdditionalInfo from '../AdditionalInfo';
import SubmitButton from '../SubmitButton';

const RoundTripTab = ({
  errors,
  className,
  airportsOptions,
  onAirportChosen,
  onAirportInputChange,
  destinationInputValue,
  originInputValue,
  originInputShowSuggest,
  destinationInputShowSuggest,
  activeOptionIndex,
  setActiveOptionIndex,
  onFocus,
  onBlur,
  onDateChange,
  dateNow,
  dateInputValue,
  dateValue,
  switchCalendar,
  isOpen,
  cabinClasses,
  additionalForm,
  onCabinChange,
  switchAdditionalForm,
  passengers,
  onPassengerChange,
  big,
  submitButtonText,
  onSubmit,
  switchAirportsData,
  children,
  groupTravelView,
  onLinkClick,
  resultPriceCalendar,
  pricesPending,
  requestDataPrice,
  getCheapestInitLoadData
}) => {
  return (
    <div className={`${className} round-trip-tab`}>
      <AirportInput
        className="origin"
        options={airportsOptions}
        onAirportChosen={onAirportChosen}
        onAirportInputChange={onAirportInputChange}
        value={originInputValue}
        showSuggestions={originInputShowSuggest}
        activeOptionIndex={activeOptionIndex}
        setActiveOptionIndex={setActiveOptionIndex}
        onFocus={onFocus}
        onBlur={onBlur}
        error={errors.origin}
        switchAirportsData={switchAirportsData}
      />
      <AirportInput
        className="destination"
        options={airportsOptions}
        onAirportChosen={onAirportChosen}
        onAirportInputChange={onAirportInputChange}
        value={destinationInputValue}
        showSuggestions={destinationInputShowSuggest}
        activeOptionIndex={activeOptionIndex}
        setActiveOptionIndex={setActiveOptionIndex}
        onFocus={onFocus}
        onBlur={onBlur}
        error={errors.destination}
      />
      <DateInput
        isDateRange
        onDateChange={onDateChange}
        dateNow={dateNow}
        isOpen={isOpen}
        inputValue={dateInputValue}
        switchCalendar={switchCalendar}
        value={dateValue}
        resultPriceCalendar={resultPriceCalendar}
        pricesPending={pricesPending}
        requestDataPrice={requestDataPrice}
        getCheapestInitLoadData={getCheapestInitLoadData}
      />
      <AdditionalInfo
        cabinClasses={cabinClasses}
        additionalForm={additionalForm}
        onCabinChange={onCabinChange}
        switchAdditionalForm={switchAdditionalForm}
        passengers={passengers}
        onPassengerChange={onPassengerChange}
        error={errors.passengers}
        groupTravelView={groupTravelView}
        onLinkClick={onLinkClick}
      />
      {children}
      {big && (
        <SubmitButton
          className={big && 'button-search-campaign'}
          submit={onSubmit}
          submitButtonText={submitButtonText}
        />
      )}
    </div>
  );
};
export default RoundTripTab;

RoundTripTab.propTypes = {
  activeOptionIndex: PropTypes.number.isRequired,
  additionalForm: PropTypes.object.isRequired,
  airportsOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, label: PropTypes.string })
  ).isRequired,
  big: PropTypes.bool,
  cabinClasses: PropTypes.array.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  dateInputValue: PropTypes.string.isRequired,
  dateNow: PropTypes.object.isRequired,
  dateValue: PropTypes.array.isRequired,
  destinationInputShowSuggest: PropTypes.bool,
  destinationInputValue: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onAirportChosen: PropTypes.func.isRequired,
  onAirportInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onCabinChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onPassengerChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  originInputShowSuggest: PropTypes.bool,
  originInputValue: PropTypes.string.isRequired,
  passengers: PropTypes.object.isRequired,
  setActiveOptionIndex: PropTypes.func.isRequired,
  switchAdditionalForm: PropTypes.func.isRequired,
  switchAirportsData: PropTypes.func,
  switchCalendar: PropTypes.func.isRequired,
  resultPriceCalendar: PropTypes.array,
  pricesPending: PropTypes.bool,
  requestDataPrice: PropTypes.func.isRequired
};

RoundTripTab.defaultProps = {
  big: false,
  children: null,
  className: '',
  destinationInputShowSuggest: false,
  isOpen: false,
  originInputShowSuggest: false,
  switchAirportsData: () => {}
};
