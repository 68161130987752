export const capitalize = str => {
  if (!str) {
    return '';
  }

  return str === 'usa'
    ? str.toUpperCase()
    : str
        .split(' ')
        .map(el => el[0].toUpperCase() + el.slice(1))
        .join(' ');
};
