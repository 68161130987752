import PropTypes from 'prop-types';
import React from 'react';

const SubmitButton = ({ submit, type, isActive }) => {
  return (
    <button
      className={`button-search ${isActive ? '' : 'active-button'}`}
      type={type}
      disabled={!isActive}
      onClick={submit}
    >
      Enter now
    </button>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  submit: PropTypes.func.isRequired
};
