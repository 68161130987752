export const defineContent = path => {
  switch (path) {
    case 'europe':
      return europe;
    case '/':
      return home;
    case 'india':
      return india;
    case 'cheap':
      return cheapFlights;
    default:
      return home;
  }
};

export const AFTER_SLIDER_CLICK = 'afterSliderClick';

const europe = [
  {
    id: 0,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-paris?${AFTER_SLIDER_CLICK}=true`,
    title: 'Paris Flights from $270',
    content: 'Special deals are only on phone'
  },
  {
    id: 1,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-spain?${AFTER_SLIDER_CLICK}=true`,
    title: 'Spain Flights from $240',
    content: 'Special deals are only on phone'
  },
  {
    id: 2,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-london?${AFTER_SLIDER_CLICK}=true`,
    title: 'LON Flights from $ 290',
    content: 'Special deals are only on phone'
  }
];

const india = [
  {
    id: 0,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-hyderabad?${AFTER_SLIDER_CLICK}=true`,
    title: 'HYD Flights from $820',
    content: 'Special deals are only on phone'
  },
  {
    id: 1,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-delhi?${AFTER_SLIDER_CLICK}=true`,
    title: 'DEL Flights from $570',
    content: 'Special deals are only on phone'
  }
];

const cheapFlights = [
  {
    id: 0,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-los-angeles?${AFTER_SLIDER_CLICK}=true`,
    title: 'LAX Flights from $170 ',
    content: 'Special deals are only on phone'
  },
  {
    id: 1,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-atlanta?${AFTER_SLIDER_CLICK}=true`,
    title: 'ATL Flights from $145 ',
    content: 'Special deals are only on phone'
  },
  {
    id: 2,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-san-francisco?${AFTER_SLIDER_CLICK}=true`,
    title: 'SFO Flights from $290',
    content: 'Special deals are only on phone'
  }
];

const home = [
  {
    id: 0,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-india?${AFTER_SLIDER_CLICK}=true`,
    title: 'India Flights from $570',
    content: 'Special deals are only on phone'
  },
  {
    id: 1,
    backgroundImage:
      'https://pbs.twimg.com/profile_images/845035383108894722/hatLJfy3_400x400.jpg',
    slug: `flights/flights-to-europe?${AFTER_SLIDER_CLICK}=true`,
    title: 'Europe flights from $270',
    content: 'Special deals are only on phone'
  }
];

export const compactSearchSlugs = ['cheap', '/'];
