import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class TabsContent extends Component {
  static propTypes = {
    activeTabIndex: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired
  };

  render() {
    const { children, activeTabIndex } = this.props;
    return <div className={'tabs'}>{children[activeTabIndex]}</div>;
  }
}
