import { redirectToIBE } from './urlParams';

export const pad = number => String(number).padStart(2, '0');

export const capitalize = str => {
  if (!str) {
    return '';
  }

  return str === 'usa'
    ? str.toUpperCase()
    : str
        .split(' ')
        .map(el => el[0].toUpperCase() + el.slice(1))
        .join(' ');
};

export const getSearchFlightURL = ({
  origin,
  destination,
  startDate,
  endDate,
  phoneNumber,
  passengers,
  cabinClass,
  baseIBEURL,
  campaign,
  activeTabIndex
}) => {
  const from = origin;
  const to = destination;
  const arrival = `${startDate.getFullYear()}${pad(
    startDate.getMonth() + 1
  )}${pad(startDate.getDate())}`;
  const departure = `${endDate.getFullYear()}${pad(
    endDate.getMonth() + 1
  )}${pad(endDate.getDate())}`;

  const { adults, children, infants } = passengers;
  return redirectToIBE({
    path: `${baseIBEURL}/${
      campaign ? `${campaign}/` : ''
    }search/${from}/${to}/${arrival}${
      activeTabIndex === 0 ? `/${departure}` : ''
    }/EXACT/${cabinClass}/${adults}-adults${
      children ? `/${children}-children` : ''
    }${infants ? `/${infants}-infants` : ''}${
      phoneNumber ? `/${phoneNumber}-phoneNumber` : ''
    }`
  });
};

export const buildAirportName = ({
  id,
  airportCity: { name },
  code,
  airportCountry
}) => (id ? `${name}, (${code}), ${airportCountry.name}` : `${name}`);

export const operatingSystems = {
  WINDOWS_PHONE: 'Windows Phone',
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown'
};

export const getMobileOperatingSystem = window => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return operatingSystems.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return operatingSystems.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return operatingSystems.IOS;
  }

  return operatingSystems.UNKNOWN;
};

export const normalizeInput = (value, previousValue) => {
  if (!value) return value;

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 2)
      return `+${currentValue.slice(0, 1)}-${currentValue.slice(1)}`;

    if (cvLength < 5) {
      return `+${currentValue.slice(0, 1)}-${currentValue.slice(1, 4)}`;
    }

    if (cvLength < 8) {
      return `+${currentValue.slice(0, 1)}-${currentValue.slice(
        1,
        4
      )}-${currentValue.slice(4, 7)}`;
    }

    return `+${currentValue.slice(0, 1)}-${currentValue.slice(
      1,
      4
    )}-${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`;
  }
};

const getRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
};

const splitToChunks = (arr, chunkCount) => {
  const originArrayCopy = [...arr];
  const chunks = [];

  for (let i = chunkCount; i > 0; i--) {
    chunks.push(
      originArrayCopy.splice(0, Math.ceil(originArrayCopy.length / i))
    );
  }

  return chunks;
};

export const getRandomElementsList = (arr, countRandomElements) => {
  const randomElements = [];
  const arrayOfChunks = splitToChunks(arr, countRandomElements);

  arrayOfChunks.forEach(chunk => {
    const randomNumber = getRandomNumber(0, chunk.length - 1);

    randomElements.push(chunk[randomNumber]);
  });

  return randomElements;
};
