import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { capitalize } from '../../../../../helpers/utils';
import { MobileFooterContext } from '../../../../layout';

import SuggestionsList from './SuggestionsList';

const AirportInput = ({
  className,
  showSuggestions,
  options,
  onAirportChosen,
  onAirportInputChange,
  value,
  activeOptionIndex,
  setActiveOptionIndex,
  onFocus,
  onBlur,
  error,
  switchAirportsData
}) => {
  const mobileFooterEl = useContext(MobileFooterContext);

  return (
    <div className={`search-input ${className}`}>
      <input
        type="text"
        value={value}
        placeholder={`${capitalize(className)} Airport`}
        onChange={e => onAirportInputChange(e.target.value, className)}
        onFocus={e => {
          mobileFooterEl &&
            mobileFooterEl.current &&
            mobileFooterEl.current.classList.add('sticked-footer');

          e.target.select();
          onFocus(e.target.value, className);
        }}
        onBlur={e => {
          mobileFooterEl &&
            mobileFooterEl.current &&
            mobileFooterEl.current.classList.remove('sticked-footer');

          onBlur(className, e.target.value);
        }}
      />
      {showSuggestions ? (
        <SuggestionsList
          options={options}
          onClick={id => onAirportChosen(className, id)}
          activeOptionIndex={activeOptionIndex}
          setActiveOptionIndex={setActiveOptionIndex}
        />
      ) : null}
      {error && <p className="validationError">{error}</p>}
      {switchAirportsData && switchAirportsData.call && (
        <span
          className="icon toggle-destinations-icon flipForward"
          onClick={switchAirportsData}
        />
      )}
    </div>
  );
};

AirportInput.propTypes = {
  activeOptionIndex: PropTypes.any,
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  onAirportChosen: PropTypes.func.isRequired,
  onAirportInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.array,
  setActiveOptionIndex: PropTypes.func.isRequired,
  showSuggestions: PropTypes.bool,
  switchAirportsData: PropTypes.func,
  value: PropTypes.string.isRequired
};

export default AirportInput;

AirportInput.defaultProps = {
  error: '',
  options: [],
  showSuggestions: false
};
