import { campaign, themeMode } from '../configs';
import { useLayoutEffect, useState } from 'react';
import { DOMAINS } from '../constants/domains';

let serializedUrlParams = null;
const defaultFilter = [
  'campaign',
  'tpodmkt',
  'tpodtfn',
  'utm_device',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_campaign'
];

export const getUrlParams = data => {
  const { filter } = data || {};
  let _filter = defaultFilter;
  if (filter) {
    if (filter.type === 'only') {
      _filter = filter.data;
    } else if (filter.type === 'all') {
      _filter = [...filter.data, ...defaultFilter];
    }
  }
  if (typeof window !== 'object') {
    return true;
  }

  serializedUrlParams = {};
  window.location.search
    .replace('?', '')
    .split('&')
    .forEach(el => {
      const a = el.split('=');
      if (a.length === 2 && _filter.includes(a[0])) {
        serializedUrlParams[a[0]] = a[1];
      }
    });
  return serializedUrlParams;
};

export const getCampaignName = () => {
  const campaignFromUrl = getUrlParams().campaign;
  if (campaignFromUrl) {
    return campaignFromUrl;
  } else if (campaign) {
    return campaign;
  } else {
    return null;
  }
};

export const getDomainInfo = () => {
  let domain = getCampaignName() ? 'B' : 'A';
  return {
    name: campaign,
    domain,
    theme: 'flykart'
  };
};

export const getDomainName = (isCamelCase = true) => {
  return 'flykart';
};

export const domainCampaign = () => DOMAINS[getDomainName()];

export const isOnline = () => getDomainInfo().domain === 'B';
export const isOffline = () => getDomainInfo().domain === 'A';
export const isFlykart = () => getDomainInfo().theme === 'flykart';
export const getThemeName = () => getDomainInfo().theme;

export const generateSearchParamsString = (source, withParams) =>
  withParams
    ? `${Object.keys(source)
        .filter(el => withParams.includes(el))
        .map(el => `${el}=${source[el]}`)
        .join('&')}`
    : `${Object.keys(source)
        .map(el => `${el}=${source[el]}`)
        .join('&')}`;

export const createUrlWithParams = ({ path = '', source = getUrlParams() }) => {
  const paramsToUrl = generateSearchParamsString(source);
  return paramsToUrl ? `${path}/?${paramsToUrl}` : `${path}/`;
};

export const redirectToIBE = ({ path = '', source = getUrlParams() }) => {
  if (isCanadaDomain()) {
    source.mkt_source = 'canada';
  }
  return createUrlWithParams({ path, source });
};

export const checkLocation = paths => {
  return paths.reduce((arr, path) => {
    if (
      path === '/' &&
      window.location.pathname === path &&
      !window.location.pathname.includes('canada')
    ) {
      arr.push(path);
      return arr;
    }
    if (
      window.location.pathname.includes(path) &&
      path !== '/' &&
      !window.location.pathname.includes('canada')
    ) {
      arr.push(path);
    }
    return arr;
  }, []);
};

export const isCanadaDomain = () =>
  typeof window !== 'undefined' && window.location.pathname.includes('/canada');

export function canadaDomain() {
  const [canadaStatus, updateCanadaStatus] = useState();
  useLayoutEffect(() => {
    function updateSize(status) {
      updateCanadaStatus(status);
    }
    return window.location.pathname.includes('/canada') && updateSize(true);
  }, []);
  return canadaStatus;
}

export const isActiveLink = path => {
  return typeof window !== 'undefined' &&
    window.location.pathname.indexOf(path) === 0
    ? 'active-link'
    : '';
};
