import React from 'react';
import { formatPhone } from '../../helpers/callTracking';
import googleFivestar from '../../images/google-review.png';
import trustPilotWithStar from '../../images/trustPilotWithExcellent.png';
import './index.css';
import {
  PHONE_NUMBER,
  PHONE_NUMBER_BUSINESS
} from '../../constants/phoneNumber';

const eventsClickPhone = () => {
  sessionStorage.setItem('modalExitIntentPopup', true);
};

const getDestination = pageData => {
  let title = pageData ? pageData.title : '';
  if (title) {
    let titleLength = title.split(' ').length;
    if (titleLength > 0 && title.includes('to')) {
      title = title.split(' ').slice(4);
    } else if (titleLength === 1) {
      title = title.charAt(0).toUpperCase() + title.slice(1);
    } else {
      title = 'Book';
    }
  } else {
    title = 'Book';
  }

  return title;
};

const getTFN = (phoneNumber, pageData) => {
  let tfn = phoneNumber;
  const title = pageData.title ? pageData.title.toLowerCase() : '';
  const slug = pageData.slug ? pageData.slug.toLowerCase() : '';
  const pathName = pageData.path ? pageData.path[0].name.toLowerCase() : '';
  const continent = pageData.tag
    ? pageData.tag.continent
      ? pageData.tag.continent.toLowerCase()
      : ''
    : '';

  if (
    (continent && continent.includes('india')) ||
    (title && title.includes('india')) ||
    (slug && slug.includes('india')) ||
    (pathName && pathName.includes('india'))
  ) {
    if (pageData.tag && pageData.tag.category === 'business-class') {
      return PHONE_NUMBER_BUSINESS['india'];
    } else {
      return PHONE_NUMBER['india'];
    }
  } else if (
    (continent && continent.includes('europe')) ||
    (title && title.includes('europe')) ||
    (slug && slug.includes('europe')) ||
    (pathName && pathName.includes('europe'))
  ) {
    if (pageData.tag && pageData.tag.category === 'business-class') {
      return PHONE_NUMBER_BUSINESS['europe'];
    } else {
      return PHONE_NUMBER['europe'];
    }
  } else if (
    (continent && continent.includes('asia')) ||
    (title && title.includes('asia')) ||
    (slug && slug.includes('asia')) ||
    (pathName && pathName.includes('asia'))
  ) {
    if (
      pageData.path &&
      pageData.path.length > 1 &&
      pageData.path[1].name.toLowerCase().includes('india')
    ) {
      return PHONE_NUMBER['india'];
    } else {
      return PHONE_NUMBER['asia'];
    }
  } else if (
    (continent && continent.includes('africa')) ||
    (title && title.includes('africa')) ||
    (slug && slug.includes('africa')) ||
    (pathName && pathName.includes('africa'))
  ) {
    return PHONE_NUMBER['africa'];
  }
  return tfn;
};

const getFare = pageData => {
  let defaultFare = 449;

  return pageData.tag
    ? pageData.tag.staticLayoutBaseFare
      ? pageData.tag.staticLayoutBaseFare
      : defaultFare
    : defaultFare;
};

const FlightDeals = ({
  onSearchClick,
  phoneNumber,
  path,
  pageData,
  homePageBanner
}) => {
  const destination = getDestination(pageData);
  const tfn = getTFN(phoneNumber, pageData);
  const layoutFare = getFare(pageData);

  // define banner data, pick from home page banner config or supply defualt values
  // TODO - structure with other properties
  const bannerData = {
    price:
      homePageBanner && homePageBanner.price
        ? homePageBanner.price
        : layoutFare,
    header1:
      homePageBanner && homePageBanner.header1
        ? homePageBanner.header1
        : 'LOWER FARE ON PHONE',
    header2:
      homePageBanner && homePageBanner.header2
        ? homePageBanner.header2
        : 'THAN ONLINE',
    content:
      homePageBanner && homePageBanner.content
        ? homePageBanner.content
        : `${destination} Flights From`,
    callUsText:
      homePageBanner && homePageBanner.callUs
        ? homePageBanner.callUs
        : 'Call to Avail'
    // image and body not used anywhere
    // image: homePageBanner ? homePageBanner.image : '',
    // body: homePageBanner ? homePageBanner.body : ''
  };

  return (
    <div className="searchCont background-color-back-blue-light">
      <div className="header-container-div">
        <div className="header-middle-container-div">
          <p className="header-container fond-bold">
            {bannerData.header1 ? (
              <p className="header-container fond-bold">{bannerData.header1}</p>
            ) : (
              pageData.content && pageData.content.heading
            )}
            {bannerData.header2 && (
              <p className="header-container fond-bold">{bannerData.header2}</p>
            )}
          </p>
        </div>
      </div>

      <div className="middle-container-div">
        <div className="flight-container-div">
          <div className="flights-from">
            {bannerData.content ? (
              <>{bannerData.content}</>
            ) : (
              pageData.content && (
                <span className="business-class-flights-from-text">
                  {pageData.content.body}
                </span>
              )
            )}
          </div>
          <div
            className={
              pageData.content
                ? 'price-container price-container-business-class'
                : 'price-container'
            }
          >
            <sup className="dollar">$</sup>
            {bannerData.price}
          </div>
          <p className="call-to-avail call-to-avail-padding">
            {bannerData.callUsText}
          </p>
          {pageData.tag && pageData.image === 'business-class' ? (
            <div className="agentImg businessImg"></div>
          ) : (
            <div className="agentImg" />
          )}
        </div>
        <div className="call-to-avail-container">
          <div className="call-block">
            <a
              href={formatPhone(tfn, 'callAction')}
              onClick={eventsClickPhone}
              className="phone-wrapper"
            >
              <div
                className="call-icons dial-icon"
                id="call-us-phone-home-mobile-icon"
              />

              <span className="call-us-icon" id="call-us-phone-home-mobile">
                {formatPhone(tfn)}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="review-flex margin">
        <img src={googleFivestar} className="googleReviewImage" alt="shaped" />
        <img
          src={trustPilotWithStar}
          className="trustPilotImage"
          alt="shaped"
        />
      </div>

      {/* <div className="bottom-container flights" onClick={onSearchClick}>
        <img src={searchicon} alt="search" className="flight-icon" />
        <span>Search Flight</span>
      </div> */}
    </div>
  );
};
export default FlightDeals;
