import React from 'react';

const withBg = Page => {
  return class extends React.Component {
    state = {
      ready: false
    };

    componentDidMount() {
      this.setState({
        ready: true
      });
    }

    render() {
      return (
        <>
          {this.state.ready ? document.body.classList.add('static-page') : ''}
          <Page />
        </>
      );
    }
  };
};

export default withBg;
