import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from '../../../services/stringService';
import { useWindowSize } from '../../../helpers/useWindowSize';
import { operatingSystems } from '../../../helpers/utils';
import Modal from './Modal';

import './index.css';

function getFullTitle(slug) {
  if (!slug || !slug.split('/')[1]) {
    return '';
  }
  const fullTitle =
    slug && slug !== ''
      ? slug
          .split('/')[1]
          .split('-')
          .map(word => {
            const [first, ...rest] = word.split('');

            return [first, ...rest].join('');
          })
          .join(' ')
      : ' flights';
  const [, , destinationTitle] = fullTitle.split(' ');
  const capitalizedTitle = capitalize(destinationTitle);

  return ` to ${capitalizedTitle}`;
}

const CallUs = ({
  pageData,
  phoneNumber,
  setToggleCallUsModal,
  toggleCallUsModal,
  footerPopup
}) => {
  const wrapperRef = React.useRef(null);
  const [width] = useWindowSize();
  let operatingSystem;

  const hidePopup = () => {
    document.documentElement.style.position = 'relative';
    document.documentElement.style.overflow = 'auto';
  };
  const showPopup = () => {
    if (operatingSystem === operatingSystems.IOS) {
      document.documentElement.style.position = 'fixed';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  };

  const OpenModal = () => {
    document.addEventListener('click', handleClickOutside, {
      passive: true,
      capture: false
    });
    showPopup();
  };

  const toggleModal = () => {
    setToggleCallUsModal(false);
  };

  React.useEffect(() => {
    if (toggleCallUsModal) {
      OpenModal();
      return () => {
        hidePopup();
        document.removeEventListener('click', handleClickOutside, false);
      };
    }
  }, [toggleCallUsModal]);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      toggleModal();
    }
  };

  const openFooterModal = footerPopup ? footerPopup.showBanner : true;

  return toggleCallUsModal && openFooterModal ? (
    <Modal
      phoneNumber={phoneNumber}
      title={getFullTitle(pageData.slug)}
      wrapperRef={wrapperRef}
      toggleIsShown={setToggleCallUsModal}
      backgroundColor="background-darker"
      colotTitle="color-light"
      colorText="color-light"
      toggleCallUsModal="callUsFooter"
      popupConfig={footerPopup}
      callUsEventId="call-us-phone-footer-desktop"
      bannerId="footer-desktop"
    />
  ) : null;
};

export default CallUs;

CallUs.propTypes = {
  pageData: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired
};
