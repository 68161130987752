import PropTypes from 'prop-types';
import React from 'react';

import Tab from './Tab';

const TabsMenu = ({ tabs, onChangeTab, activeTabIndex }) => (
  <div className={'tabs_menu'}>
    {tabs.map((tab, i) => (
      <Tab
        key={`${tab.value}_${i}`}
        id={i}
        text={tab.text}
        value={tab.value}
        isChecked={activeTabIndex === i}
        onChange={onChangeTab}
      />
    ))}
  </div>
);

TabsMenu.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      isChecked: PropTypes.bool
    })
  ).isRequired
};

export default TabsMenu;
