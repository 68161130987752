import PropTypes from 'prop-types';
import React from 'react';

const SubmitButton = ({ className = '', submit, submitButtonText }) => {
  return (
    <button
      className={`button-search ${className}`}
      type="button"
      onClick={submit}
    >
      {submitButtonText}
    </button>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  submit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string
};
