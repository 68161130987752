module.exports = {
  marketingUrl: 'https://www.flykart.com/',
  baseIBEURL: 'https://new-flights.flykart.com',
  baseApiIBEURL: 'https://flights.travelopod.com',
  callTrackingApiUrl: 'https://call-tracking.travelopod.com/api',
  baseApiUrl: 'https://ops-api.travelopod.com/api',
  modalCallUsTimeout: 60000,
  trustPilotScriptTimeout: 1000,
  defaultModalDelayDays: 0,
  defaultCookieBannerDelayDays: 30,
  modalCallUsLocalStorageName: 'hasModal25BeenShown',
  zendeskID: 'dG0pD58XMjQaTyGwIOR53F9WTMAZfXGL',
  exitIntentModalStatus: true,
  campaign: '',
  goggleAnalyticsId: '',
  GA4_ID: '',
  googleOptimizeId: '',
  experimentId: '',
  themeMode: 'flykart',
  cookieBannerTimeout: 120000,
  GTM_ID: ''
};
