/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import Header from './Header/header';
import CompactSearch from './CompactSearch';
import Footer from './Footer/footer';
import './layout.css';
import TrustpilotInfoBlock from './TrustpilotInfoBlock';
import CallUs from './Modals/CallUs/CallUs';
import CallUsFooter from './Modals/CallUs/CallUsFooter';
import ExitIntentPopup from './Modals/ExitIntentPopup/ExitIntentPopup';
import BannerFooter from './BannerFooter/BannerFooter';
import Banner from './Banner/Banner';
import configs, { trustPilotScriptTimeout } from '../configs';
import CookieBanner from './Banner/CookieBanner';

import {
  DEFAULT_PHONE,
  setupCallTracking,
  updatePhoneNumber
} from '../helpers/callTracking';
import {
  isCanadaDomain,
  isOffline,
  isFlykart,
  getDomainName
} from '../helpers/urlParams';
import { ToastContainer } from 'react-toastify';
import { PHONE_NUMBER, PHONE_NUMBER_BUSINESS } from '../constants/phoneNumber';
import { useWindowSize } from '../helpers/useWindowSize';
import { mobileWidth } from '../configs/constants';
import isBefore from 'date-fns/fp/isBefore';

export const MobileFooterContext = React.createContext(null);

const FREEZE_PAGES = ['flights', 'india'];
function isFreezePage() {
  if (
    typeof window !== 'undefined' &&
    (window.location.pathname === '/' ||
      window.location.pathname === '/canada/')
  ) {
    return true;
  }
  return (
    typeof window !== 'undefined' &&
    window.location.pathname
      .split('/')
      .filter(path => FREEZE_PAGES.includes(path)).length > 0
  );
}

const Layout = ({
  children,
  pageData,
  title,
  passPhoneNumber,
  isLanding,
  isStaticPage,
  isErrorPage,
  image,
  staticPhoneNumber,
  isBannersShown,
  isBannerApiResponseReceived,
  ipAction
}) => {
  const canadaPageData = {
    ...pageData,
    tag: {
      continent: 'canada',
      fixedNumber: true
    }
  };

  const getTrustpilotScript = () => {
    const script = document.createElement('script');
    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    script.type = 'text/javascript';
    return script;
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [phoneNumber, setPhoneNumber] = useState(
    staticPhoneNumber || DEFAULT_PHONE
  );
  const [infoSearch, setInfoSearch] = useState('');
  const [toggleModals, setToggleModals] = useState(false);
  const [toggleCallUsModal, setToggleCallUsModal] = useState(false);
  const [isShownExitModal, toggleIsShownExitModal] = useState(false);
  const [trustpilotBgShow, setTrustpilotBgShow] = useState(true);
  const [showSearchForm, changeShowSearchForm] = React.useState(false);
  const [titlePage404, setTitlePage404] = useState('');
  const mobileFooterEl = useRef(null);
  const [landingBanners, setLandingBanners] = useState({});

  const offlineCampaign = isOffline();
  const flykartTheme = isFlykart();
  const [width] = useWindowSize();

  React.useEffect(() => {
    if (isBannerApiResponseReceived) {
      if (ipAction !== 'allow') {
        if (ipAction === 'block') {
          window.location.pathname = '/404';
        } else if (
          (ipAction === 'redirect' || !isBannersShown) &&
          !window.location.href.includes('online')
        ) {
          navigate(`/online${window.location.pathname}`);
        }
      }
    }
  }, [isBannerApiResponseReceived, ipAction, isBannersShown]);

  React.useEffect(() => {
    // api call
    const getBannerConfigs = async () => {
      const bannerConfig = await fetch(
        `${configs.baseApiUrl}/banners/landingpage`,
        {
          headers: {
            domain: 'flykart',
            slug: pageData.slug
          }
        }
      );
      const { data } = await bannerConfig.json();
      setLandingBanners(data);
      return;
    };
    getBannerConfigs();
  }, [pageData.slug]);

  const passPhoneNumberFn = useCallback(passPhoneNumber, [phoneNumber]);

  const updatePhone = useCallback(
    async IATA => {
      let phoneNumber;
      if (pageData.tag && pageData.tag.fixedNumber) {
        if (pageData.tag.category === 'business-class') {
          phoneNumber = PHONE_NUMBER_BUSINESS[pageData.tag.continent];
        } else {
          phoneNumber = PHONE_NUMBER[pageData.tag.continent];
        }
      } else if (IATA === 'XYZ') {
        phoneNumber = '18052654930';
      } else {
        phoneNumber = await updatePhoneNumber(IATA, getDomainName());
      }

      setPhoneNumber(phoneNumber);
      passPhoneNumberFn(phoneNumber);
    },
    [pageData, passPhoneNumberFn]
  );

  let destinationIATA = '';
  let originIATA = '';

  if (pageData && pageData.pairs && pageData.pairs[0] && !isLanding) {
    destinationIATA = pageData.pairs[0].destinationIATA;
    if (pageData.slug.includes('flights-from')) {
      originIATA = pageData.pairs[0].originIATA;
    }
  }
  if (isLanding && pageData.destinationIATA) {
    destinationIATA = pageData.destinationIATA;
  }

  React.useEffect(() => {
    setTimeout(() => {
      document.head.appendChild(getTrustpilotScript());
      // if (offlineCampaign) {
      //   document.head.appendChild(getZendeskScript());
      // }
      setTrustpilotBgShow(false);
    }, trustPilotScriptTimeout);

    setupCallTracking();

    !staticPhoneNumber && updatePhone(destinationIATA);
  }, [destinationIATA, offlineCampaign, staticPhoneNumber, updatePhone]);

  const getInfoSearch = temp => {
    // infoSearch.changeSeach(temp);
  };

  useEffect(() => {
    const title = window.location.pathname.includes('/flights/flights-')
      ? `Find Cheap Flights ${window.location.pathname
          .split('-')
          .splice(1)
          .map(
            el =>
              (!el ? '' : el[0].toUpperCase()) + el.slice(1).replace(/\//g, '')
          )
          .join(' ')}`
      : 'Find Cheap  Flights';
    setTitlePage404(title);
  }, []);
  if (
    isBannerApiResponseReceived &&
    ipAction === 'redirect' &&
    !window.location.pathname.includes('/online')
  ) {
    return null;
  }
  let phoneNum = isCanadaDomain() ? '18006749301' : phoneNumber;
  let finalPageData = isCanadaDomain() ? canadaPageData : pageData;
  return (
    <div
      className="freeze-layout"
      style={{
        position: width < mobileWidth && isFreezePage() && 'relative'
      }}
    >
      <ToastContainer />
      <div id="cookiebanner">
        <CookieBanner />
      </div>
      <Header
        isCanada={isCanadaDomain()}
        siteTitle={title || data.site.siteMetadata.title}
        phoneNumber={phoneNum}
      />
      <Banner />
      {!isStaticPage && (
        <>
          <MobileFooterContext.Provider value={mobileFooterEl}>
            <CompactSearch
              pageData={finalPageData}
              isErrorPage={isErrorPage}
              destinationIATA={destinationIATA}
              originIATA={originIATA}
              title={title || titlePage404}
              phoneNumber={phoneNum}
              updatePhone={updatePhone}
              getInfoSearch={getInfoSearch}
              image={image}
              isBannersShown={isBannersShown}
              showSearchForm={showSearchForm}
              changeShowSearchForm={changeShowSearchForm}
              homePageBanner={landingBanners.homePageBanner}
            />
          </MobileFooterContext.Provider>

          {!flykartTheme && (
            <TrustpilotInfoBlock trustpilotBgShow={trustpilotBgShow} />
          )}
        </>
      )}
      <main>{children}</main>
      <Footer slug={pageData.slug} isCanada={isCanadaDomain()} />
      {offlineCampaign && isBannersShown && (
        <BannerFooter
          phoneNumber={phoneNum}
          onInitToggleCallUsModal={than => {
            setToggleCallUsModal(than);
          }}
          mobileFooterEl={mobileFooterEl}
          footerBanner={landingBanners.footerBanner || {}}
        />
      )}
      {/* // this is the default popup on website */}
      {!isStaticPage && (
        <CallUs
          pageData={finalPageData}
          phoneNumber={phoneNum}
          toggleModals={toggleModals}
          showModal={showSearchForm}
          isBannersShown={isBannersShown}
          defaultPopup={landingBanners.defaultPopup}
        />
      )}
      {
        // this is the banner that opens on footer banner click on desktop
        <CallUsFooter
          pageData={finalPageData}
          phoneNumber={phoneNum}
          setToggleCallUsModal={that => {
            setToggleCallUsModal(that);
          }}
          toggleCallUsModal={toggleCallUsModal}
          footerPopup={landingBanners.footerPopup}
        />
      }
      <ExitIntentPopup
        destinationIATA={destinationIATA}
        onInitChangeSeach={that => {
          setInfoSearch(that);
        }}
        onInitToggleModal={that => {
          setToggleModals(that);
        }}
        toggleIsShownExitModal={toggleIsShownExitModal}
        isShownExitModal={isShownExitModal}
        trustpilotBgShow={trustpilotBgShow}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isLanding: PropTypes.bool,
  isStaticPage: PropTypes.bool,
  pageData: PropTypes.object,
  passPhoneNumber: PropTypes.func.isRequired,
  title: PropTypes.string,
  getInfoSearch: PropTypes.func
};

export default Layout;

Layout.defaultProps = {
  isLanding: false,
  isStaticPage: false,
  pageData: {},
  title: ''
};

// -------------------------- REMOVED CODE --------------------------

// ------------- removed banners
// special banners for following 3 links and only for Mobile View:
// 1. flights/cheap-flight-tickets: ->CheapFlightsBanner / CheapFlightsDesktopBanner
// 2. flights/cheap-flights-to-south-west-usa: ->CheapFlightsUSBanner
// 3. flights/cheap-flights-from-united-state: ->CheapFlightsUSBanner
// 4. flights/flights-to-india: ->CheapFlightsIndiaBanner

// ---> imports
// import {
//   CheapFlightsBanner,
//   CheapFlightsUSBanner,
//   CheapFlightsIndiaBanner
// } from './Modals/CallUs/CheapFlightsPopup';
// import { CheapFlightsDesktopBanner } from './Modals/CallUs/CheapFlightsDesktopPopup';

// ---> url matching for banners
// const inWindowPathname = path => {
//   if (typeof window !== 'undefined') {
//     return window.location.pathname.includes(path);
//   }
//   return false;
// };
// const cheapFlightTicketsBanner = inWindowPathname(
//   'flights/cheap-flight-tickets'
// );
// const cheapFlightsToSouthWestUsaBanner = inWindowPathname(
//   'flights/cheap-flights-to-south-west-usa'
// );
// const cheapFlightsFromUnitedStateBanner = inWindowPathname(
//   'flights/cheap-flights-from-united-state'
// );
// const cheapFlightsIndiaBanner = inWindowPathname('flights/flights-to-india');

// ---> banner calls
// {/* Banner for link 'flights-to-india' */ }
// {
//   !isStaticPage && isBannersShown && cheapFlightsIndiaBanner && (
//     <CheapFlightsIndiaBanner />
//   )
// }
// {/* Banner for link 'cheap-flight-tickets' */ }
// {
//   !isStaticPage && isBannersShown && cheapFlightTicketsBanner && (
//     <CheapFlightsBanner />
//   )
// }

// {/* Banner for links 'cheap-flights-to-south-west-usa' and 'cheap-flights-from-united-state' */ }
// {
//   !isStaticPage &&
//     isBannersShown &&
//     (cheapFlightsToSouthWestUsaBanner ||
//       cheapFlightsFromUnitedStateBanner) && <CheapFlightsUSBanner />
// }
// {/*Desktop Banner for link 'cheap-flight-tickets' */ }
// {
//   !isStaticPage &&
//     isBannersShown &&
//     (cheapFlightTicketsBanner ||
//       cheapFlightsToSouthWestUsaBanner ||
//       cheapFlightsFromUnitedStateBanner) && <CheapFlightsDesktopBanner />
// }

// ------------- removed zendesk
// const getZendeskScript = () => {
//   const script = document.createElement('script');
//   script.async = true;
//   script.type = 'text/javascript';
//   script.innerHTML =
//     'window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");$.src="https://v2.zopim.com/?dG0pD58XMjQaTyGwIOR53F9WTMAZfXGL";z.t=+new Date;$.type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");';
//   return script;
// };
