import PropTypes from 'prop-types';
import React from 'react';

const CabinClass = ({
  cabin: { value, label },
  checked,
  onCabinChange,
  id
}) => (
  <div className={`checkbox-service ${checked ? 'active' : ''}`}>
    <input
      type="radio"
      id={id}
      name="service-class"
      value={value}
      checked={checked}
      onChange={() => onCabinChange(id)}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

CabinClass.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onCabinChange: PropTypes.func.isRequired
};

export default CabinClass;

CabinClass.defaultProps = {
  checked: false
};
