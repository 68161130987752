import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LogoImg from '../../components/Image/LogoImg';
import LogoFlykart from '../../components/Image/LogoFlykart';
import { useWindowSize } from '../../helpers/useWindowSize';
import ListItem from '../ListItem';
import Link from '../Link';
import './header.css';
import LinkUrl from '../LinkUrl';
import {
  getThemeName,
  isCanadaDomain,
  isFlykart
} from '../../helpers/urlParams';
import { formatPhone } from '../../helpers/callTracking';

const Header = ({ phoneNumber, isCanada }) => {
  const [dropdownOpen, set] = useState(false);
  const [NavMobileActive, showNavMobile] = useState(false);
  const [width] = useWindowSize();
  const handleDropdown = () => set(!dropdownOpen);
  const handleMobileMenu = () => showNavMobile(!NavMobileActive);
  const flightsLocation = isCanadaDomain() ? '/canada/flights' : '/flights';
  const logoUrl = isCanadaDomain() ? '/canada' : '';
  const flykartTheme = isFlykart();
  const dataTheme = getThemeName();

  const eventsClickPhone = () => {
    sessionStorage.setItem('modalExitIntentPopup', true);
  };
  return (
    <header>
      <div className="wrapper d-flex">
        <div className="hamburger">
          <div
            className={`navigation-mobile d-flex ${
              NavMobileActive ? 'show' : ''
            }`}
            onClick={handleMobileMenu}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <LinkUrl
          path={logoUrl}
          className={`logo ${flykartTheme ? 'flykart-logo' : ''}`}
        >
          {flykartTheme ? <LogoFlykart alt="Logo" /> : <LogoImg alt="Logo" />}
        </LinkUrl>
        <ul
          className={`navigation d-flex ${NavMobileActive ? 'show' : ''}`}
          data-theme={dataTheme}
        >
          <ListItem
            href={isCanada ? '/canada/win-a-trip' : '/win-a-trip'}
            value="Win a Trip"
            image="corporate"
          />

          <ListItem href={flightsLocation} value="Flights" image="flights" />
          <ListItem
            href="https://www.booking.com/?aid=870169"
            target="_blank"
            value="Hotels"
            image="hotels"
            rel="noopener"
          />
          <ListItem href="/cars" value="Cars" image="cars" />
          <ListItem
            href="https://planner.travelopod.com"
            target="_blank"
            value="Trip planner"
            image="trip-planner"
            rel="noopener"
          />
          {width <= 1024 && (
            <>
              <ListItem
                href={
                  isCanada
                    ? '/canada/more/corporate-travel'
                    : '/more/corporate-travel'
                }
                value="Corporate travel"
                image="trip-planner"
              />
              <ListItem
                href={
                  isCanada ? '/canada/more/group-travel' : '/more/group-travel'
                }
                value="Group travel"
                image="trip-planner"
              />
              <ListItem
                href={isCanada ? '/canada/blog' : '/blog'}
                value="Blog"
                image="hotels"
              />
              <ListItem href="/vacation" value="Vacation" image="corporate" />
            </>
          )}

          {width >= 1024 && (
            <li>
              <a className="navigation-link" onClick={handleDropdown}>
                More
                <img
                  className="expand"
                  src="/../../images/icons/expand.png"
                  alt="expand"
                />
              </a>
              {dropdownOpen && (
                <ul className="dropdown">
                  <ListItem
                    href={
                      isCanada
                        ? '/canada/more/corporate-travel'
                        : '/more/corporate-travel'
                    }
                    value="Corporate travel"
                    image="trip-planner"
                  />
                  <ListItem
                    href={
                      isCanada
                        ? '/canada/more/group-travel'
                        : '/more/group-travel'
                    }
                    value="Group travel"
                    image="trip-planner"
                  />
                  <ListItem
                    href={isCanada ? '/canada/blog' : '/blog'}
                    value="Blog"
                    image="hotels"
                  />
                  <ListItem
                    href="/vacation"
                    value="Vacation"
                    image="corporate"
                  />
                </ul>
              )}
            </li>
          )}
        </ul>
        {!flykartTheme && (
          <div className="call-us d-flex" id="call-us-phone-header">
            <p className="text">Call us 24/7</p>
            <Link
              number={formatPhone(phoneNumber, 'callAction')}
              className="call-us-phone"
              onClick={eventsClickPhone}
              id="call-us-phone-header-desktop"
            >
              {formatPhone(phoneNumber)}
            </Link>
            <Link
              number={formatPhone(phoneNumber, 'callAction')}
              className="call-us-phone-mobile"
              onClick={eventsClickPhone}
              id="call-us-phone-header-mobile"
            >
              <img
                className="phone-icon"
                src="/../../images/icons/shape.png"
                alt="shape"
              />
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ''
};

export default Header;
