import React from 'react';
import './index.css';

const Trustpilot = () => (
  <React.Fragment>
    <div className="desktop-trustpilot d-flex">
      <p>Loved by millions</p>
      <p>Excellent</p>
      <div className="trustpilot-stars"></div>
      <span>9.2 out of 10 based on 3702 reviews</span>
      <span>9.2 out of 10</span>
      <div className="trustpilot-logo"></div>
    </div>
    <div className="mobile-trustpilot"></div>
  </React.Fragment>
);

export default Trustpilot;
