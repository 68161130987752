import PropTypes from 'prop-types';
import React from 'react';
import LogoImg from '../../components/Image/LogoImg';
import LogoFlykart from '../../components/Image/LogoFlykart';
import MarketingMessages from '../MarketingMessages/MarketingMessages';
import './footer.css';
import LinkUrl from '../LinkUrl';
import Menu from '../Menu/Menu';
import { isFlykart, getThemeName } from '../../helpers/urlParams';

import canada from './data/canada.json';
import usa from './data/usa.json';

const slugIncludesCanada = slug => !!slug && slug.includes('canada');

const Footer = ({ slug, isCanada }) => {
  const data = isCanada ? canada : usa;
  const logoUrl = isCanada ? '/canada' : '';
  const flykartTheme = isFlykart();
  const dataTheme = getThemeName();
  return (
    <footer data-theme={dataTheme}>
      <div className="wrapper">
        {!flykartTheme && (
          <div className="container d-flex">
            {data.columns.map(({ title, list }) => (
              <div className="col" key={title}>
                <h6 className="title">{title}</h6>
                <ul>
                  {list.map(({ slug, title }) => (
                    <li key={slug}>
                      <LinkUrl path={slug}>{title}</LinkUrl>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        <div className="footer-menu d-flex">
          <LinkUrl
            path={logoUrl}
            className={`footer-logo ${flykartTheme ? 'flykart-logo' : ''}`}
          >
            {flykartTheme ? <LogoFlykart alt="Logo" /> : <LogoImg alt="Logo" />}
          </LinkUrl>
          <Menu isCanada={isCanada} />
          <div className="social-icon-container">
            <p>Follow us all over the place</p>
            <div className="social-icons d-flex">
              <a href="https://www.facebook.com/Travelopod/">
                <img
                  src="/../../images/social-icons/facebook.png"
                  alt="facebook"
                />
              </a>
              <a href="https://twitter.com/TravelopodUS/">
                <img
                  src="/../../images/social-icons/twitter.png"
                  alt="twitter"
                />
              </a>
              <a href="https://www.youtube.com/user/travelopod/">
                <img
                  src="/../../images/social-icons/youtube.png"
                  alt="youtube"
                />
              </a>
              <a href="https://www.instagram.com/travelopod/">
                <img
                  src="/../../images/social-icons/instagram.png"
                  alt="instagram"
                />
              </a>
              <a href="https://www.linkedin.com/company/travelopod/">
                <img
                  src="/../../images/social-icons/linkedin.png"
                  alt="linkedin"
                />
              </a>
              <a href="https://www.pinterest.com/travelopod/">
                <img
                  src="/../../images/social-icons/pinterest.png"
                  alt="pinterest"
                />
              </a>
            </div>
          </div>
        </div>
        <p className="copyright">
          © 2010 - {new Date().getFullYear()}{' '}
          {flykartTheme
            ? 'Flykart Inc (flykart.com) DBA - Airline Ticket Fare CST # 2112418-40'
            : 'Travelopod Inc (travelopod.com) DBA - Airline Ticket Fare CST # 2112418-40'}
        </p>
      </div>
      <div className="advantage-logos d-flex">
        <div className="col d-flex">
          <img
            className="logo-card"
            src="/../../images/advantage-icon/arc.svg"
            alt="arc"
          />
          <img
            className="logo-card"
            src="/../../images/advantage-icon/bbb.svg"
            alt="bbb"
          />
          <img
            className="logo-card"
            src="/../../images/advantage-icon/norton.png"
            alt="norton"
          />
        </div>
        <div className="col d-flex">
          <img
            className="logo-card"
            src="/../../images/advantage-icon/visa.svg"
            alt="visa"
          />
          <img
            className="logo-card"
            src="/../../images/advantage-icon/mastercard.svg"
            alt="mastercard"
          />
          <img
            className="logo-card"
            src="/../../images/advantage-icon/discover.svg"
            alt="discover"
          />
          <img
            className="logo-card"
            src="/../../images/advantage-icon/amex.svg"
            alt="amex"
          />
        </div>
        <MarketingMessages />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {};

export default Footer;
