import React from 'react';

const ModalClose = ({ handleClick }) => (
  <React.Fragment>
    <span
      id="modal_close-timeout"
      className="modal-close"
      onClick={handleClick}
    >
      <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>close</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Popups"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="call-us-popup-2"
            transform="translate(-1087.000000, -35.000000)"
            fill="#1879c0"
            fillRule="nonzero"
          >
            <g id="pop-up" transform="translate(15.000000, 9.000000)">
              <g id="Group-3">
                <polygon
                  id="close"
                  points="1078 30.9565217 1082.95652 26 1084 27.0434783 1079.04348 32 1084 36.9565217 1082.95652 38 1078 33.0434783 1073.04348 38 1072 36.9565217 1076.95652 32 1072 27.0434783 1073.04348 26"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  </React.Fragment>
);

export default ModalClose;
