export const PHONE_NUMBER = {
  europe: '18005459284',
  asia: '18556093981',
  africa: '18005833036',
  india: '18005459284',
  canada: '18006749301',
  marketing: '18336350071'
};

export const PHONE_NUMBER_BUSINESS = {
  india: '18052654990',
  europe: '18052965880',
  canada: '18006749301'
};
