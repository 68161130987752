import { addMonths } from 'date-fns';

export const formatDateCityPair = date => {
  const dateObj = new Date(date);
  const dateParts = dateObj.toDateString().split(' ');
  return `${dateParts[1]} ${dateParts[2]}`;
};

export const departDateMessages = new Date()
  .toISOString()
  .slice(0, 10)
  .replace(/-/g, '');
export const returnDateMessages = new Date(
  new Date().getTime() + 14 * 24 * 60 * 60 * 1000
)
  .toISOString()
  .slice(0, 10)
  .replace(/-/g, '');

export const formatDateApi = inputDate => {
  const dateObj = new Date(inputDate);
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const date = ('0' + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  return `${year}-${month}-${date}`;
};

export const formatDateCalendar = date => {
  const dateObj = new Date(date);
  const dateParts = dateObj.toDateString().split(' ');
  return `${dateParts[1]} ${dateParts[2]} ${dateParts[3]}`;
};

export const formatShortWeekday = (locale, date) => {
  return new Date(date)
    .toDateString()
    .slice(0, -4)
    .replace(' ', ', ')
    .substr(0, 2);
};

export const plusDaysInDate = (date, day) => {
  return new Date(date.setDate(date.getDate() + day));
};

export const setCityPairDates = (startDate, endDate) => {
  let formattedStartDate = new Date(startDate);
  let formattedEndDate = new Date(endDate);
  let differenceinMs = Math.abs(formattedEndDate - formattedStartDate);

  let currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();
  let currentDate = new Date();

  // if older Date then modifying the startDate, else returning the same StartDate
  if (currentDate > formattedStartDate) {
    // checking upcoming Date
    let upcomingMonth;
    // for currentMonth=> 9 to 11 and 1, the upcoming month would be feb and for less than 8 but more than 1(feb), the upcoming month is october
    if (currentMonth === 0) {
      upcomingMonth = 1;
    } else if (currentMonth <= 8) {
      upcomingMonth = 9;
    } else if (currentMonth > 8) {
      upcomingMonth = 1;
    }

    // changing the Month
    formattedStartDate = new Date(startDate).setMonth(upcomingMonth);

    // changing the Year to currentYear
    let finalStartDate = new Date(formattedStartDate).setYear(currentYear);

    // adding differenceinMs to endDate
    formattedEndDate = new Date(finalStartDate + differenceinMs);

    // returning dates in proper format
    finalStartDate = new Date(finalStartDate);
    let finalEndDate = new Date(formattedEndDate);
    return {
      newStartDate: finalStartDate,
      newEndDate: finalEndDate
    };
  } else {
    return { newStartDate: formattedStartDate, newEndDate: formattedEndDate };
  }
};
