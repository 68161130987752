import React from 'react';
import LinkUrl from '../LinkUrl';
import './styles.css';

const menuData = [
  {
    path: '/aboutus',
    title: 'About Us'
  },
  {
    path: '/affiliates',
    title: 'Affiliates'
  },
  {
    path: '/terms-conditions',
    title: 'Terms & Conditions**'
  },
  {
    path: '/our-culture',
    title: 'Our Culture'
  },
  {
    path: '/refer-and-earn-at-travelopod',
    title: 'Refer & Earn'
  },
  {
    path: '/contact-us',
    title: 'Contact Us'
  },
  {
    path: '/privacy-policy',
    title: 'Privacy Policy'
  },
  {
    path: '/bookingrules',
    title: 'Booking Rules'
  },
  {
    path: '/updates',
    title: 'Updates'
  }
];

const setActive = (realpath, path) => {
  return realpath.includes(path) && realpath.split('/').length === 3
    ? 'active'
    : '';
};

class Menu extends React.Component {
  state = {
    active: ''
  };

  componentDidMount() {
    this.setState({
      active: location.pathname
    });
  }

  render() {
    const { active } = this.state;
    const { isCanada } = this.props;
    const elements = menuData.map(({ path, title }) => {
      return (
        <li key={title}>
          <LinkUrl
            path={isCanada ? `/canada${path}` : path}
            className={setActive(active, path)}
          >
            {title}
          </LinkUrl>
        </li>
      );
    });

    return <ul className="items d-flex">{elements}</ul>;
  }
}

export default Menu;
