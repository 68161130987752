import React from 'react';
// import FlightDeals from '../CompactFlightDeals';
import FindFlightsBlock from '../FindFlightsBlock/FindFlightsBlock';
import { useWindowSize } from '../../helpers/useWindowSize';
// import WhyBookOnPhone from '../WhyBookOnPhone/WhyBookOnPhone';
import { getDomainName, checkLocation } from '../../helpers/urlParams';
import { compactSearchSlugs } from '../../helpers/compactSearchContent';
import { mobileWidth } from '../../configs/constants';

import sales from '../../images/icons/sales-icon.svg';
import tick from '../../images/icons/bullet-tick.svg';

import './index.css';
import HomePageBanner from '../HomePageBanner';

const CompactSearch = ({
  destinationIATA,
  originIATA,
  title,
  phoneNumber,
  updatePhone,
  getInfoSearch,
  image,
  isErrorPage,
  pageData,
  isBannersShown,
  showSearchForm,
  changeShowSearchForm,
  homePageBanner
}) => {
  const [width] = useWindowSize();
  const [path, updatePath] = React.useState();

  // const domain = getDomainName();
  const isCompactDeal =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('is_compact_search')
      : false;
  if (isCompactDeal) {
    originIATA = '';
    destinationIATA = '';
  }
  const renderFindFlights = () => (
    <FindFlightsBlock
      pageData={pageData}
      isErrorPage={isErrorPage}
      destinationIATA={destinationIATA}
      originIATA={originIATA}
      title={title}
      phoneNumber={phoneNumber}
      updatePhone={updatePhone}
      getInfoSearch={getInfoSearch}
      image={image}
      isBannersShown={isBannersShown}
      homePageBanner={homePageBanner}
    />
  );

  // use case for 3 links with new compactSearch block

  React.useEffect(() => {
    const [currentLocation] = checkLocation(compactSearchSlugs);
    currentLocation ? updatePath(currentLocation) : updatePath('none');
  }, []);

  return (
    <>
      {/* Added 5 pixels to the mobileWidth value as mobileWidth was interpreted as 430px instead of 425px.(in the mobile view-chrome-browser) */}
      {width >= mobileWidth + 5 ? (
        // desktop-view
        renderFindFlights()
      ) : (
        <>
          {/* mobile view */}
          {homePageBanner && homePageBanner.showBanner && (
            <HomePageBanner
              phoneNumber={phoneNumber}
              path={path}
              pageData={pageData}
              homePageBanner={homePageBanner}
            />
          )}
          {renderFindFlights()}
        </>
      )}
    </>
  );
};

export default CompactSearch;

const reservationsBlock = () => {
  return (
    <>
      <div className="reservations-block">
        <div className="reservations-desk-block">
          <div className="reservations-desk-header">
            <p>RESERVATION</p>
            <p>DESK</p>
          </div>
          <div className="desk-services-list">
            <div className="service-list-row">
              <span className="service-list-item">
                <img className="img-icon" src={tick} alt="-" /> New Bookings
              </span>
              <span className="service-list-item">
                <img className="img-icon" src={tick} alt="-" /> Refunds
              </span>
            </div>
            <div className="service-list-row">
              <span className="service-list-item">
                <img className="img-icon" src={tick} alt="-" /> Customer Service
              </span>
              <span className="service-list-item">
                <img className="img-icon" src={tick} alt="-" /> Changes
              </span>
            </div>
            <div className="service-list-row">
              <span className="service-list-item">
                <img className="img-icon" src={tick} alt="-" /> Cancellations
              </span>
            </div>
          </div>
        </div>
        <div className="reservations-offer">
          <img src={sales} alt="" className="sales-icon" />
          <span>15% off on New Bookings avaliable until</span>
          &nbsp;
          <span className="last-date-offer">Jan 18</span>
        </div>
      </div>
    </>
  );
};
