import React, { useState, useEffect } from 'react';
import { baseApiUrl } from '../../configs';
import {
  departDateMessages,
  returnDateMessages
} from '../../helpers/dateHelper';
import { useWindowSize } from '../../helpers/useWindowSize';

import './MarketingMessages.css';

const MarketingMessages = () => {
  const [messages, setMessages] = useState([]);
  const [hideMessage, setHideMessage] = useState(null);
  const [width] = useWindowSize();

  async function fetchProduct() {
    let response = await fetch(
      `${baseApiUrl}/marketing/messages?departDate=${departDateMessages}&returnDate=${returnDateMessages}`
    );
    response = await response.json();
    setMessages(response);
  }

  useEffect(() => {
    if (sessionStorage.getItem('messagesSessionStorage') === 'true') {
      const timer = setTimeout(() => {
        fetchProduct();
      }, 25000);
      return () => clearTimeout(timer);
    } else {
      fetchProduct();
      sessionStorage.setItem('messagesSessionStorage', 'true');
    }
  }, []);

  delete messages[hideMessage];

  return (
    <>
      {width > 768 && (
        <ul className="message-list">
          {messages.map((item, index) => (
            <li
              key={item.text + index}
              className={`marketing-messages animate-${index}`}
            >
              <div
                className="close-icon"
                onClick={() => setHideMessage(index)}
              />
              <p className="text-messages">{item.text}</p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
export default MarketingMessages;
