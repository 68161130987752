import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useWindowSize } from '../../../../../helpers/useWindowSize';
import { Calendar, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { useEffect } from 'react';
import './index.css';

const DateInput = ({
  className,
  inputValue,
  isOpen,
  dateNow,
  onDateChange,
  isDateRange,
  switchCalendar,
  oneWayDateChange
}) => {
  const [width] = useWindowSize();
  const isCompactDeal =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('is_compact_search')
      : false;

  const openCalendar = () => {
    isCompactDeal &&
      typeof localStorage !== 'undefined' &&
      localStorage.removeItem('is_compact_search');
    switchCalendar();
  };

  const [state, setState] = useState([
    {
      startDate: dateNow,
      endDate: dateNow,
      key: 'selection'
    }
  ]);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (
      state[0].startDate &&
      state[0].endDate &&
      state[0].endDate.getTime() !== state[0].startDate.getTime()
    ) {
      onDateChange(state);
    }
  }, [onDateChange, state]);

  useEffect(() => {
    if (!isDateRange) {
      switchCalendar();
      oneWayDateChange(date);
    }
  }, [date, isDateRange, oneWayDateChange, switchCalendar]);

  return (
    <div className={`search-input ${className} date`}>
      <input
        type="text"
        readOnly
        value={isCompactDeal ? '' : inputValue}
        placeholder={`Departure Date - End Date`}
        onClick={openCalendar}
      />
      {isOpen && width > 700 ? (
        <div className="date-picker-wrapper">
          {isDateRange ? (
            <DateRange
              onChange={item => setState([item.selection])}
              showMonthAndYearPickers={false}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              minDate={dateNow}
              maxDate={addDays(dateNow, 365)}
              direction="horizontal"
              showSelectionPreview={false}
              showPreview={true}
            />
          ) : (
            <Calendar
              date={date}
              minDate={dateNow}
              maxDate={addDays(dateNow, 365)}
              showMonthAndYearPickers={false}
              onChange={item => setDate(item)}
            />
          )}
        </div>
      ) : (
        isOpen &&
        width <= 700 && (
          <>
            <div className="modal-date-picker">
              <div className="modal-header">
                <h5 className="date-value">{inputValue}</h5>
                <div className="close-modal-date" onClick={openCalendar} />
              </div>
              {isDateRange ? (
                <DateRange
                  editableDateInputs={true}
                  onChange={item => setState([item.selection])}
                  showMonthAndYearPickers={false}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  months={3}
                  direction="vertical"
                  minDate={dateNow}
                  maxDate={addDays(dateNow, 365)}
                  scroll={{ enabled: true }}
                />
              ) : (
                <Calendar
                  months={3}
                  scroll={{ enabled: true }}
                  showMonthAndYearPickers={false}
                  date={date}
                  minDate={dateNow}
                  maxDate={addDays(dateNow, 365)}
                  onChange={item => setDate(item)}
                />
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default DateInput;

DateInput.propTypes = {
  className: PropTypes.string,
  dateNow: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  isDateRange: PropTypes.bool,
  isOpen: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  oneWayDateChange: PropTypes.func.isRequired,
  switchCalendar: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  resultPriceCalendar: PropTypes.array,
  pricesPending: PropTypes.bool,
  requestDataPrice: PropTypes.func.isRequired,
  getCheapestInitLoadData: PropTypes.func.isRequired
};

DateInput.defaultProps = {
  className: '',
  isDateRange: false,
  isOpen: false
};
