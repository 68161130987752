import React, { useState } from 'react';
import './CookieBanner.css';
import cookie from '../../images/cookiey.svg';
import { useEffect } from 'react';
import { withPrefix } from 'gatsby';
import {
  cookieBannerTimeout,
  defaultCookieBannerDelayDays
} from '../../configs';

const CookieBanner = () => {
  const [isBannerShowing, setIsBannerShowing] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsBannerShowing(true);
    }, cookieBannerTimeout);
  }, []);

  const hideBanner = () => {
    setIsBannerShowing(false);
    const now = new Date();
    const timestamp = new Date(
      now.getTime() + 60000 * 60 * 24 * Number(defaultCookieBannerDelayDays)
    ).getTime();
    localStorage.setItem('DEFAULT_COOKIE_POPUP', timestamp);
  };

  function isDelayed() {
    const item = localStorage.getItem('DEFAULT_COOKIE_POPUP');
    const now = new Date().getTime();
    return item && now < parseInt(item, 10);
  }
  return (
    <>
      {isBannerShowing && !isDelayed() && (
        <div id="cookie-banner">
          <img src={withPrefix(cookie)} alt="cookie" />
          <p>
            This website uses cookies or similar technologies to make your
            browsing experience better. By continuing to use our website, you
            agree to our
            <a href="https://www.travelopod.com/privacy-policy/">
              privacy policy
            </a>
            and cookie policy.
          </p>
          <div id="btn-banner">
            <button id="disable-cookies-banner" onClick={hideBanner}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
