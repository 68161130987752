import PropTypes from 'prop-types';
import React from 'react';

import './PassengersInput.css';

const PassengersInput = ({
  passenger: { label, text, id },
  count,
  onPassengerChange
}) => (
  <div className="passenger-select-option d-flex">
    <div>
      {label}
      <p>{text}</p>
    </div>
    <div className="passenger-counter-block d-flex">
      <button
        type="button"
        className="passenger-count-btn d-flex"
        onClick={() => onPassengerChange(-1, id)}
      >
        <span className="minus-icon" />
      </button>
      <span className="passenger-type-count">{count}</span>
      <button
        type="button"
        className="passenger-count-btn d-flex"
        onClick={() => onPassengerChange(1, id)}
      >
        <span className="plus-icon" />
      </button>
    </div>
  </div>
);

PassengersInput.propTypes = {
  count: PropTypes.number.isRequired,
  onPassengerChange: PropTypes.func.isRequired
};

export default PassengersInput;
