import { defaultModalDelayDays } from '../configs';
import { operatingSystems } from './utils';

const DEFAULT_POPUP = 'default_popup_delay_temp';
let operatingSystem;

export function markAsOpened() {
  if (defaultModalDelayDays) {
    const now = new Date();
    const timestamp = new Date(
      now.getTime() + 60000 * 60 * 24 * Number(defaultModalDelayDays)
    ).getTime();
    localStorage.setItem(DEFAULT_POPUP, timestamp);
  }
}

export function isDelayed() {
  const item = localStorage.getItem(DEFAULT_POPUP);
  const now = new Date().getTime();

  return item && now < parseInt(item, 10);
}

export const hidePopup = () => {
  document.documentElement.style.position = 'relative';
  document.documentElement.style.overflow = 'auto';
};

export const showPopup = () => {
  if (operatingSystem === operatingSystems.IOS) {
    document.documentElement.style.position = 'fixed';
  } else {
    document.documentElement.style.overflow = 'hidden';
  }
};
