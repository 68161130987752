const BANNER = 'banner';

export function markAsClosed() {
  localStorage.setItem(BANNER, true);
}

export function wasClosed() {
  const bannerState =
    typeof window !== 'undefined' && localStorage.getItem(BANNER);

  return bannerState && JSON.parse(bannerState);
}
