import './SearchForm.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { buildAirportName, getSearchFlightURL } from '../../../helpers/utils';
import TabsMenu from './components/TabsMenu';
import TabsContent from './components/TabsContent';
import RoundTripTab from './components/TabsContent/RoundTripTab';
import OneWayTab from './components/TabsContent/OneWayTab';
import SubmitButton from './components/SubmitButton';
import {
  domainCampaign,
  getCampaignName,
  createUrlWithParams,
  getThemeName,
  getUrlParams
} from '../../../helpers/urlParams';
import { baseApiUrl, marketingUrl } from '../../../configs';
import {
  GetCheapestInitLoad,
  GetCheapestRecommendations
} from '../../../services/apiService';

const buildAdditionalFormMessage = (passengers, cabin) => {
  const passengersCount = getPassengersCount(passengers);
  return `${passengersCount} ${
    passengersCount > 1 ? 'travelers' : 'traveler'
  }, ${cabin}`;
};

const getPassengersCount = passengers =>
  Object.values(passengers).reduce((a, b) => a + b, 0);

const tabsMap = {
  RoundTripTab,
  OneWayTab
};

class SearchForm extends Component {
  static defaultProps = {
    baseIBEURL: '',
    big: false,
    campaign: '',
    destination: '',
    getInfoSearch: () => {},
    maxPassengersCount: 9,
    minPassengersCount: 1,
    onSubmit: null,
    origin: '',
    showOnlyTab: '',
    setError: () => {},
    submitButtonText: 'Search',
    passengers: {
      adults: 0,
      children: 0,
      infants: 0
    }
  };

  static propTypes = {
    baseIBEURL: PropTypes.string,
    big: PropTypes.bool,
    campaign: PropTypes.string,
    children: PropTypes.any,
    destination: PropTypes.string,
    endDate: PropTypes.any,
    getInfoSearch: PropTypes.func,
    maxPassengersCount: PropTypes.number,
    minPassengersCount: PropTypes.number,
    onSubmit: PropTypes.func,
    origin: PropTypes.string,
    passengers: PropTypes.any,
    serviceClass: PropTypes.any,
    setError: PropTypes.func,
    showOnlyTab: PropTypes.string,
    startDate: PropTypes.any,
    submitButtonText: PropTypes.string,
    updatePhone: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const maxPassengersCount = props.maxPassengersCount;
    const minPassengersCount = props.minPassengersCount;
    const additionalFormInputValue = '1 traveler, Economy';
    this.config = {
      tabs: [
        {
          text: 'Round-trip',
          value: 'RT'
        },
        {
          text: 'One-way',
          value: 'OW'
        }
      ],
      passengers: {
        adults: { label: 'Adults', text: '12+ years' },
        children: { label: 'Children', text: '2-11 years' },
        infants: { label: 'Infants', text: '0-2 years' }
      },
      maxPassengersCount: maxPassengersCount,
      defaultPassengersAdult: 1,
      cabinClasses: [
        {
          value: 'ECONOMY',
          label: 'Economy'
        },
        {
          value: 'PREMIUM_ECONOMY',
          label: 'Premium economy'
        },
        {
          value: 'BUSINESS',
          label: 'Business'
        },
        {
          value: 'FIRST',
          label: 'First'
        }
      ],
      errorText: {
        originSubmit: "Please provide 'From' airport",
        incorrectAirport: 'Enter the correct destination',
        destinationSubmit: "Please provide 'To' airport",
        passengersLimit: `Maximum ${maxPassengersCount} Travellers are allowed!`,
        passengersInfantsToAdults:
          'Number of infants exceeds maximum allowed per adult passenger',
        minimumPassengers: `Minimum ${minPassengersCount} Travellers are allowed!`
      }
    };

    const isCompactDeal =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('is_compact_search')
        : false;
    this.now = new Date();
    this.initStart = !isCompactDeal
      ? new Date(
          this.now.getFullYear(),
          this.now.getMonth(),
          this.now.getDate() + 7
        )
      : '';
    this.initEnd = !isCompactDeal
      ? new Date(
          this.now.getFullYear(),
          this.now.getMonth(),
          this.now.getDate() + 14
        )
      : '';
    this.state = {
      errors: {
        origin: '',
        destination: '',
        passengers: ''
      },
      activeTabIndex: 0,
      origin: {
        value: '',
        showSuggestions: false,
        airport: {}
      },
      destination: {
        value: '',
        showSuggestions: false,
        airport: {}
      },
      datePicker: {
        isOpen: false,
        arrival: {
          value: this.initStart
        },
        departure: {
          value: this.initEnd
        }
      },
      additionalForm: {
        isOpen: false,
        inputValue: props.isBusinessClass
          ? '1 traveler, Business'
          : additionalFormInputValue,
        passengers: {
          adults: 1,
          children: 0,
          infants: 0
        },
        cabinClassIndex: props.isBusinessClass ? 2 : 0
      },
      airportsOptions: [],
      activeOptionIndex: 0,
      resultPriceCalendar: [],
      pricesPending: false,
      isEvent: false
    };
  }

  async componentDidMount() {
    const {
      destination,
      origin,
      serviceClass,
      passengers,
      passengersAdults,
      startDate,
      endDate
    } = this.props;
    window.addEventListener('click', this.handleOutsideClick, {
      passive: true
    });

    if (destination) {
      await this.getAirports(destination);
      const airport = this.state.airportsOptions[0];
      airport &&
        airport.id &&
        this.onAirportChosen('destination', airport.id, true);
    }
    if (origin) {
      await this.getAirports(origin);
      const airport = this.state.airportsOptions[0];
      airport && airport.id && this.onAirportChosen('origin', airport.id, true);
    }
    if (serviceClass) {
      const id = this.config.cabinClasses.findIndex(
        ({ value }) => value === serviceClass
      );
      this.onCabinChange(id, this.setupPassengers);
    } else {
      this.setupPassengers();
    }
    if (startDate && endDate) {
      this.onDateChange([
        {
          startDate: new Date(startDate * 1000),
          endDate: new Date(endDate * 1000)
        }
      ]);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOutsideClick);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.origin === nextProps.origin &&
      this.props.destination === nextProps.destination &&
      this.props.big === nextProps.big &&
      this.props.campaign === nextProps.campaign &&
      this.props.baseIBEURL === nextProps.baseIBEURL &&
      this.state === nextState &&
      !nextProps.children
    ) {
      return false;
    }
    return true;
  }

  setupPassengers = () => {
    const { passengers, minPassengersCount } = this.props;
    const passengerCount = Object.keys(passengers).length
      ? Object.values(passengers).reduce((acc, cur) => acc + cur)
      : 0;
    const newPassengers = { adults: 0, children: 0, infants: 0 };

    if (passengerCount) {
      if (passengerCount < minPassengersCount) {
        Object.keys(passengers).forEach(el => {
          newPassengers[el] += passengers[el];
        });
        newPassengers.adults += minPassengersCount - 1 - passengerCount;
      } else {
        Object.keys(passengers).forEach(el => {
          newPassengers[el] += passengers[el];
        });
      }
    } else if (minPassengersCount > 1) {
      newPassengers.adults += minPassengersCount - 1;
    }

    if (Object.values(newPassengers).reduce((acc, cur) => acc + cur)) {
      this.onPassengerChange(
        Object.keys(newPassengers).map(el => ({
          value: newPassengers[el],
          id: el
        }))
      );
    }
  };

  keyboardEventsHandler = (input, e) => {
    const { airportsOptions, activeOptionIndex } = this.state;
    const { keyCode } = e;
    switch (keyCode) {
      case 38: {
        // up arrow
        e.preventDefault();
        this.setState(({ activeOptionIndex, airportsOptions }) => ({
          activeOptionIndex:
            activeOptionIndex === 0
              ? airportsOptions.length - 1
              : activeOptionIndex - 1
        }));
        break;
      }
      case 40: {
        // down arrow
        e.preventDefault();
        this.setState(({ activeOptionIndex, airportsOptions }) => ({
          activeOptionIndex:
            activeOptionIndex === airportsOptions.length - 1
              ? 0
              : activeOptionIndex + 1
        }));
        break;
      }
      case 13: {
        // enter
        const airport = airportsOptions[activeOptionIndex];
        airport && airport.id && this.onAirportChosen(input, airport.id);
        break;
      }
      case 27: {
        // esc
        this.hideSuggestList(input);
        break;
      }
      default: {
      }
    }
  };

  onChangeTab = index => {
    this.setState(({ datePicker, additionalForm }) => ({
      activeTabIndex: index,
      datePicker: {
        ...datePicker,
        isOpen: false
      },
      additionalForm: {
        ...additionalForm,
        isOpen: false
      }
    }));
  };

  onAirportChosen = (input, id, isEventGA) => {
    const { updatePhone } = this.props;
    const airport =
      this.state.airportsOptions.find(el => el.id === id) ||
      this.state[input].airport;
    if (airport) {
      !isEventGA && input === 'destination' && updatePhone(airport.code);
      this.setState(
        {
          [input]: {
            value: buildAirportName(airport),
            showSuggestions: false,
            airport
          }
        },
        state => {
          const origin = this.state.origin.airport.code;
          const destination = this.state.destination.airport.code;
          if (origin && destination) {
            this.getCheapestInitLoadData();
          }
          this.props.getInfoSearch(this.state);
        }
      );
    }
    this.hideSuggestList(input);
  };

  onAirportInputChange = async (value, input) => {
    if (value.length > 2) {
      this.getAirports(value);
    } else {
      this.hideSuggestList(input);
    }
    this.setState(state => ({
      [input]: {
        ...state[input],
        value
      }
    }));
    if (!this.state[input].showSuggestions && value.length > 2) {
      this.showSuggestList(input, value);
    }
  };

  showSuggestList = input => {
    this.setState(state => ({
      [input]: {
        ...state[input],
        showSuggestions: true
      }
    }));
    this.eventHandler = e => {
      this.keyboardEventsHandler(input, e);
    };
    document.addEventListener('keydown', this.eventHandler);
  };

  hideSuggestList = input => {
    this.resetAirportsOptions();
    this.setState(state => ({
      activeOptionIndex: 0,
      [input]: {
        ...state[input],
        showSuggestions: false
      }
    }));
    document.removeEventListener('keydown', this.eventHandler);
  };

  getAirports = async searchString => {
    let airports = [];

    try {
      const response = await fetch(`${baseApiUrl}/airports?q=${searchString}`);
      if (response.ok) {
        airports = await response.json();
        this.setState({
          airportsOptions: airports.data.length ? airports.data : []
        });
      } else {
        this.resetAirportsOptions();
      }
    } catch (e) {
      this.resetAirportsOptions();
    }
  };

  resetAirportsOptions = () => {
    this.setState({
      airportsOptions: []
    });
  };

  setActiveOptionIndex = index => {
    this.setState({
      activeOptionIndex: index
    });
  };

  onAirportFieldFocus = (value, input) => {
    this.state[input].airport.id
      ? this.getAirports(this.state[input].airport.airportCity.name)
      : value && this.getAirports(value);
    value.length > 2 && this.showSuggestList(input);
    this.setError(input, '');
  };

  onAirportFieldBlur = (input, value) => {
    const {
      state: { airportsOptions, activeOptionIndex },
      config: { errorText }
    } = this;
    const airport =
      airportsOptions[activeOptionIndex] || this.state[input].airport;

    let textError = '';

    if (airport && airport.id) {
      this.onAirportChosen(input, airport.id);
    }
    if (value && (!airport || !airport.id)) {
      textError = errorText.incorrectAirport;
      this.onAirportInputChange('', input);
    }
    this.hideSuggestList(input);
    this.setError(input, textError);
  };

  switchAirportsData = () => {
    const { origin, destination, datePicker, additionalForm } = this.state;
    this.setState(
      {
        origin: destination,
        destination: origin,
        datePicker: {
          ...datePicker,
          isOpen: false
        },
        additionalForm: {
          ...additionalForm,
          isOpen: false
        }
      },
      state => {
        this.props.getInfoSearch(this.state);
      }
    );

    this.setError('origin', '');
    this.setError('destination', '');
    this.setError('passengers', '');
    this.hideSuggestList('origin');
    this.hideSuggestList('destination');
  };

  setError = (input, text) => {
    this.setState(({ errors }) => ({ errors: { ...errors, [input]: text } }));
  };

  oneWayDateChange = date => {
    this.setState({
      datePicker: {
        arrival: {
          value: date
        },
        departure: {
          value: date
        },
        isOpen: false
      }
    });
  };

  onDateChange = value => {
    this.setState(
      {
        datePicker: {
          arrival: {
            value: value[0].startDate
          },
          departure: {
            value: value[0].endDate
          },
          isOpen: false
        }
      },
      state => {
        this.props.getInfoSearch(this.state);
      }
    );
  };

  buildInputValueString = value => {
    if (Array.isArray(value)) {
      const formatDate = value.map(item => {
        if (item === '' || null) {
          return 'Select a date';
        }
        return new Date(item)
          .toDateString()
          .slice(0, -4)
          .replace(' ', ', ');
      });
      return formatDate.join(' - ');
    } else {
      return new Date(value)
        .toDateString()
        .slice(0, -4)
        .replace(' ', ', ');
    }
  };

  paxReference = additionalForm => {
    const additionalConst = {
      adults: 'ADT',
      children: 'CH',
      infants: 'INF'
    };

    return Object.keys(additionalForm.passengers)
      .filter(item => additionalForm.passengers[item] > 0)
      .map(key => {
        return {
          type: additionalConst[key],
          number: additionalForm.passengers[key]
        };
      });
  };

  switchCalendar = () => {
    this.setState(({ datePicker, additionalForm }) => ({
      datePicker: {
        ...datePicker,
        isOpen: !datePicker.isOpen
      },
      additionalForm: {
        ...additionalForm,
        isOpen: false
      }
    }));
  };

  getCheapestInitLoadData = async () => {
    const {
      config: { cabinClasses },
      state: { origin, destination, additionalForm }
    } = this;

    const data = {
      from: origin.airport.code,
      to: destination.airport.code,
      cabinType: cabinClasses[additionalForm.cabinClassIndex].value,
      paxReference: this.paxReference(additionalForm),
      domain: domainCampaign()
    };
    this.setState({ pricesPending: true });
    const cheapestData = await GetCheapestInitLoad(data);
    this.setState({
      resultPriceCalendar: cheapestData || []
    });
    this.setState({ pricesPending: false });
  };

  cheapestRecommendationsParams = async date => {
    const {
      config: { cabinClasses },
      state: { origin, destination, additionalForm }
    } = this;
    const data = {
      from: origin.airport.code,
      to: destination.airport.code,
      cabinType: cabinClasses[additionalForm.cabinClassIndex].value,
      departDate: new Date(date).getTime() / 1000,
      paxReference: this.paxReference(additionalForm),
      domain: domainCampaign()
    };
    const recommendationsData = await GetCheapestRecommendations(data);
    this.setState({
      resultPriceCalendar: recommendationsData || []
    });
  };

  requestDataPrice = value => {
    const {
      state: { origin, destination }
    } = this;
    if (origin.value && destination.value) {
      return this.cheapestRecommendationsParams(value);
    }
  };

  switchAdditionalForm = () => {
    this.setState(
      ({ additionalForm, datePicker }) => ({
        datePicker: {
          ...datePicker,
          isOpen: false
        },
        additionalForm: {
          ...additionalForm,
          isOpen: !additionalForm.isOpen
        }
      }),
      state => {
        this.props.getInfoSearch(this.state);
      }
    );
  };

  onCabinChange = (id, cb) => {
    this.setState(
      ({ additionalForm }) => ({
        additionalForm: { ...additionalForm, cabinClassIndex: id }
      }),
      () => {
        this.updateAdditionalInfoMessage();
        this.props.getInfoSearch(this.state);
        if (cb && cb.call) {
          cb();
        }
      }
    );
  };

  handleOutsideClick = e => {
    const current = this.formRef;
    if (
      current &&
      !current.contains(e.target) &&
      !e.target.classList.contains('react-calendar__month-view__days__day')
    ) {
      const adults = this.state.additionalForm.passengers.adults;
      const children = this.state.additionalForm.passengers.children;
      const infants = this.state.additionalForm.passengers.infants;
      const cabinClassIndex = this.state.additionalForm.cabinClassIndex;
      const cabinClasses = this.config.cabinClasses[cabinClassIndex].label;
      const { utm_source } = getUrlParams();

      if (this.state.additionalForm.isOpen) {
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'PassengerType', {
            event_category: domainCampaign(),
            event_label: cabinClasses,
            event_source: utm_source || 'Flykart'
          });
        }
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'PassengerType', {
            event_category: domainCampaign(),
            event_label: `adults: ${adults}, children: ${children}, infants: ${infants}`,
            event_source: utm_source || 'Flykart'
          });
        }
      }
      this.setState(({ datePicker, additionalForm }) => ({
        datePicker: {
          ...datePicker,
          isOpen: false
        },
        additionalForm: {
          ...additionalForm,
          isOpen: false
        }
      }));
    }
  };

  validatePassengers = (passengers, { value, id }) => {
    const {
      config: { maxPassengersCount },
      props: { minPassengersCount }
    } = this;
    const originPassengerList = { ...passengers };
    const newCount = passengers[id] + value;
    const minCount = id === 'adults' ? 1 : 0;

    if (newCount < minCount) {
      return originPassengerList;
    }
    passengers[id] = newCount < minCount ? minCount : newCount;
    const isDecrement = value < 0;
    const passengersCount = getPassengersCount(passengers);
    const canIncrease = passengersCount <= maxPassengersCount;
    const canDecrease = passengersCount >= minPassengersCount;
    const isInfantsLessAdults = passengers.infants <= passengers.adults;

    switch (true) {
      case !canDecrease && isDecrement: {
        this.setError('passengers', this.config.errorText.minimumPassengers);
        return originPassengerList;
      }
      case !isInfantsLessAdults: {
        this.setError(
          'passengers',
          this.config.errorText.passengersInfantsToAdults
        );
        return originPassengerList;
      }
      case !canIncrease: {
        this.setError('passengers', this.config.errorText.passengersLimit);
        return originPassengerList;
      }
      default: {
        this.setError('passengers', '');
        return passengers;
      }
    }
  };

  onPassengerChange = (value, id) => {
    const {
      state: { additionalForm }
    } = this;
    let passengers = { ...additionalForm.passengers };
    const newPassengers = id ? [{ value, id }] : value;

    newPassengers.forEach(passenger => {
      passengers = this.validatePassengers(passengers, passenger);
    });

    this.setState(
      {
        additionalForm: {
          ...this.state.additionalForm,
          passengers: {
            ...passengers
          }
        }
      },
      () => {
        this.updateAdditionalInfoMessage();
        this.props.getInfoSearch(this.state);
      }
    );
  };

  updateAdditionalInfoMessage = () => {
    const {
      config: { cabinClasses },
      state: {
        additionalForm: { passengers, cabinClassIndex }
      }
    } = this;

    const inputValue = buildAdditionalFormMessage(
      passengers,
      cabinClasses[cabinClassIndex].label
    );
    this.setState(
      ({ additionalForm }) => ({
        additionalForm: { ...additionalForm, inputValue }
      }),
      state => {
        this.props.getInfoSearch(this.state);
      }
    );
  };

  onSubmit = () => {
    const {
      config: { errorText },
      state: { origin, destination }
    } = this;
    if (!origin.airport.id) {
      this.setError('origin', errorText.originSubmit);
      this.props.setError();
    }
    if (!destination.airport.id) {
      this.setError('destination', errorText.destinationSubmit);
      this.props.setError();
    }
    if (origin.airport.id && destination.airport.id) {
      this.submit();
    }
  };

  submit = () => {
    const {
      config: { cabinClasses },
      state: {
        origin,
        destination,
        datePicker,
        additionalForm,
        activeTabIndex
      },
      props: { baseIBEURL, onSubmit }
    } = this;
    const data = {
      origin: origin.airport.code,
      destination: destination.airport.code,
      endDate: datePicker.departure.value,
      startDate: datePicker.arrival.value,
      cabinClass: cabinClasses[additionalForm.cabinClassIndex].value,
      passengers: additionalForm.passengers,
      campaign: getCampaignName(),
      phoneNumber: this.props.phoneNumber,
      baseIBEURL,
      activeTabIndex
    };
    if (onSubmit) {
      this.props.onSubmit(data);
    } else {
      const url = getSearchFlightURL(data);
      window.open(url, '_self');
    }
    const DateConverter = {
      StartDate: new Date(data.startDate).toLocaleString('en-in', {
        month: 'short',
        day: 'numeric'
      }),
      EndDate: new Date(data.endDate).toLocaleString('en-in', {
        month: 'short',
        day: 'numeric'
      })
    };

    const SEARCHED_FLIGHT_DETAILS = {
      from: data.origin,
      to: data.destination,
      startDate: DateConverter.StartDate,
      endDate: DateConverter.EndDate,
      activeTabIndex: data.activeTabIndex,
      newIBEUrl: getSearchFlightURL(data)
    };
    const currentDomain = domainCampaign();
    // 1. If no cached data is available, simply cache the searched data
    // 2. If one cached data is is available, cache newly searched data with condition that
    //    the newly searched data is different from already cached data
    // 3. If two cached datas are available, update cache-1 with cache-2 data and cache-2 with newly searched data
    //    with the condition that newly searched data is different from cache-2

    let cachedSearch1 =
      JSON.parse(localStorage.getItem('searched_flight_details_1')) || {};
    let cachedSearch2 =
      JSON.parse(localStorage.getItem('searched_flight_details_2')) || {};

    // cachedSearch1 is absent or the domain we are on, doesn't have an entry in cachedSearch1
    if (!cachedSearch1[currentDomain]) {
      cachedSearch1[currentDomain] = SEARCHED_FLIGHT_DETAILS;
    }
    // second is empty or first has domain entry and second doesn't have any entry for the specific domain
    else if (!cachedSearch2[domainCampaign()]) {
      // fill second only if url is different than first
      if (
        cachedSearch1[currentDomain].newIBEUrl !==
        SEARCHED_FLIGHT_DETAILS.newIBEUrl
      ) {
        cachedSearch2[currentDomain] = SEARCHED_FLIGHT_DETAILS;
      }
    }
    // case when both are present
    else {
      // url doesn't match with second then dump second into first and new into second
      if (
        cachedSearch2[currentDomain].newIBEUrl !==
        SEARCHED_FLIGHT_DETAILS.newIBEUrl
      ) {
        cachedSearch1[currentDomain] = cachedSearch2[currentDomain];
        cachedSearch2[currentDomain] = SEARCHED_FLIGHT_DETAILS;
      }
    }

    cachedSearch1[currentDomain] &&
      localStorage.setItem(
        'searched_flight_details_1',
        JSON.stringify(cachedSearch1)
      );
    cachedSearch2[currentDomain] &&
      localStorage.setItem(
        'searched_flight_details_2',
        JSON.stringify(cachedSearch2)
      );
  };

  redirectToGroupPage = () => {
    const {
      config: { cabinClasses },
      state: { origin, destination, datePicker, additionalForm }
    } = this;

    const endDate = datePicker.departure.value.getTime() / 1000;
    const startDate = datePicker.arrival.value.getTime() / 1000;
    const serviceClass = cabinClasses[additionalForm.cabinClassIndex].value;
    const adults = additionalForm.passengers.adults;
    const children = additionalForm.passengers.children;
    const infants = additionalForm.passengers.infants;

    const data = {
      ...(origin.airport.code && { from: origin.airport.code }),
      ...(destination.airport.code && { to: destination.airport.code }),
      ...(endDate && { endDate }),
      ...(startDate && { startDate }),
      ...(serviceClass && { serviceClass }),
      ...(adults && { adults }),
      ...(children && { children }),
      ...(infants && { infants })
    };
    const urlWithParams = createUrlWithParams({
      path: `${marketingUrl}more/group-travel`
    });
    const additionalParams = Object.keys(data).map(el => `${el}=${data[el]}`);
    const fullUrl =
      urlWithParams.indexOf('?') !== -1
        ? `${urlWithParams}&${additionalParams.join('&')}`
        : `${urlWithParams}?${additionalParams.join('&')}`;
    window.open(fullUrl, '_self');
  };

  render() {
    const {
      config: { tabs, cabinClasses, passengers },
      now,
      state: {
        errors,
        activeTabIndex,
        origin,
        destination,
        airportsOptions,
        activeOptionIndex,
        datePicker,
        additionalForm,
        resultPriceCalendar,
        pricesPending
      },
      props: { big, showOnlyTab, children, submitButtonText, groupTravelView }
    } = this;
    const RenderSingleTab = showOnlyTab && tabsMap[showOnlyTab];
    const dataTheme = getThemeName();
    return (
      <div
        className={`search ${big ? 'big' : ''}`}
        ref={ref => (this.formRef = ref)}
        data-theme={dataTheme}
      >
        {showOnlyTab ? (
          <RenderSingleTab
            errors={errors}
            airportsOptions={airportsOptions}
            onAirportChosen={this.onAirportChosen}
            onAirportInputChange={this.onAirportInputChange}
            originInputValue={origin.value}
            destinationInputValue={destination.value}
            originInputShowSuggest={origin.showSuggestions}
            destinationInputShowSuggest={destination.showSuggestions}
            activeOptionIndex={activeOptionIndex}
            setActiveOptionIndex={this.setActiveOptionIndex}
            onBlur={this.onAirportFieldBlur}
            onFocus={this.onAirportFieldFocus}
            switchAirportsData={this.switchAirportsData}
            onDateChange={this.onDateChange}
            dateNow={now}
            dateValue={[datePicker.arrival.value, datePicker.departure.value]}
            switchCalendar={this.switchCalendar}
            dateInputValue={this.buildInputValueString([
              datePicker.arrival.value,
              datePicker.departure.value
            ])}
            isOpen={datePicker.isOpen}
            cabinClasses={cabinClasses}
            passengers={passengers}
            additionalForm={additionalForm}
            onCabinChange={this.onCabinChange}
            switchAdditionalForm={this.switchAdditionalForm}
            onPassengerChange={this.onPassengerChange}
            big={big}
            submitButtonText={submitButtonText}
            className="search-flights-tab"
            onSubmit={this.onSubmit}
            children={children}
            groupTravelView={groupTravelView}
            onLinkClick={this.redirectToGroupPage}
            resultPriceCalendar={resultPriceCalendar}
            pricesPending={pricesPending}
            requestDataPrice={this.requestDataPrice}
            getCheapestInitLoadData={this.getCheapestInitLoadData}
          />
        ) : (
          <>
            <TabsMenu
              tabs={tabs}
              onChangeTab={this.onChangeTab}
              activeTabIndex={activeTabIndex}
            />
            <TabsContent activeTabIndex={activeTabIndex}>
              <RoundTripTab
                errors={errors}
                airportsOptions={airportsOptions}
                onAirportChosen={this.onAirportChosen}
                onAirportInputChange={this.onAirportInputChange}
                originInputValue={origin.value}
                destinationInputValue={destination.value}
                originInputShowSuggest={origin.showSuggestions}
                destinationInputShowSuggest={destination.showSuggestions}
                activeOptionIndex={activeOptionIndex}
                setActiveOptionIndex={this.setActiveOptionIndex}
                onBlur={this.onAirportFieldBlur}
                onFocus={this.onAirportFieldFocus}
                switchAirportsData={this.switchAirportsData}
                onDateChange={this.onDateChange}
                dateNow={now}
                dateValue={[
                  datePicker.arrival.value,
                  datePicker.departure.value
                ]}
                switchCalendar={this.switchCalendar}
                dateInputValue={this.buildInputValueString([
                  datePicker.arrival.value,
                  datePicker.departure.value
                ])}
                isOpen={datePicker.isOpen}
                cabinClasses={cabinClasses}
                passengers={passengers}
                additionalForm={additionalForm}
                onCabinChange={this.onCabinChange}
                switchAdditionalForm={this.switchAdditionalForm}
                onPassengerChange={this.onPassengerChange}
                big={big}
                submitButtonText={submitButtonText}
                className="search-flights-tab"
                onSubmit={this.onSubmit}
                children={children}
                onLinkClick={this.redirectToGroupPage}
                resultPriceCalendar={resultPriceCalendar}
                pricesPending={pricesPending}
                requestDataPrice={this.requestDataPrice}
                getCheapestInitLoadData={this.getCheapestInitLoadData}
              />
              <OneWayTab
                errors={errors}
                airportsOptions={airportsOptions}
                onAirportChosen={this.onAirportChosen}
                onAirportInputChange={this.onAirportInputChange}
                originInputValue={origin.value}
                destinationInputValue={destination.value}
                originInputShowSuggest={origin.showSuggestions}
                destinationInputShowSuggest={destination.showSuggestions}
                activeOptionIndex={activeOptionIndex}
                setActiveOptionIndex={this.setActiveOptionIndex}
                onBlur={this.onAirportFieldBlur}
                onFocus={this.onAirportFieldFocus}
                switchAirportsData={this.switchAirportsData}
                onDateChange={this.onDateChange}
                oneWayDateChange={this.oneWayDateChange}
                dateNow={now}
                dateValue={datePicker.arrival.value}
                switchCalendar={this.switchCalendar}
                className="search-flights-tab"
                dateInputValue={this.buildInputValueString(
                  datePicker.arrival.value
                )}
                isOpen={datePicker.isOpen}
                cabinClasses={cabinClasses}
                passengers={passengers}
                additionalForm={additionalForm}
                onCabinChange={this.onCabinChange}
                switchAdditionalForm={this.switchAdditionalForm}
                onPassengerChange={this.onPassengerChange}
                big={big}
                submitButtonText={submitButtonText}
                onSubmit={this.onSubmit}
                children={children}
                onLinkClick={this.redirectToGroupPage}
                resultPriceCalendar={resultPriceCalendar}
                pricesPending={pricesPending}
                requestDataPrice={this.requestDataPrice}
                getCheapestInitLoadData={this.getCheapestInitLoadData}
              />
            </TabsContent>
          </>
        )}

        {!big && (
          <SubmitButton
            submit={this.onSubmit}
            submitButtonText={submitButtonText}
          />
        )}
      </div>
    );
  }
}

export default SearchForm;
