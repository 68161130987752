import PropTypes from 'prop-types';
import React from 'react';

const Tab = ({ text, value, id, isChecked, onChange }) => {
  return (
    <>
      <input
        type="radio"
        className={'input-trip-type'}
        id={`radio${id}`}
        name="trip-type"
        value={value}
        checked={isChecked}
        onChange={() => onChange(id)}
      />
      <label className={'label-trip-type'} htmlFor={`radio${id}`}>
        {text}
      </label>
    </>
  );
};

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default Tab;

Tab.defaultProps = {
  isChecked: false
};
