import PropTypes from 'prop-types';
import React from 'react';
import LinkUrl from './LinkUrl';
import { isActiveLink } from '../helpers/urlParams';

const ListItem = props => {
  const src = `/../../images/icons/${props.image}.png`;
  const activeLink = isActiveLink(props.href);

  return (
    <li className={activeLink}>
      <LinkUrl
        path={props.href}
        target={props.target}
        className="navigation-link"
        rel={props.rel}
      >
        <div className="icon">
          <img src={src} alt={props.image} />
        </div>
        {props.value}
      </LinkUrl>
    </li>
  );
};

ListItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  alt: PropTypes.string,
  filename: PropTypes.string,
  value: PropTypes.string
};

ListItem.defaultProps = {
  className: '',
  href: '',
  alt: '',
  filename: '',
  value: ''
};

export default ListItem;
